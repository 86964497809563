import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { selectTopicsByProjectId } from 'common/redux/commonData/topics/selectors';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { useAppSelector } from 'store/hooks';
import { filterTopics } from 'utils/filterTopics';

/**
 * Хук для получения списка рубрик из нового стейта, возвращает список активных отсортированных
 * рубрик и объект с количеством рубрик для отрисовки в разном разрешении
 * @param projectID - id проекта
 */
export const useTopicsMenu = (projectID: ProjectType['id'] | undefined) => {
  const projectTopics = useAppSelector(
    selectTopicsByProjectId(projectID),
    shallowEqual,
  );

  return useMemo(() => {
    return filterTopics(projectTopics, projectID);
  }, [projectTopics, projectID]);
};

/**
 * Хук для получения готового списка рубрик
 * @param projectID – id вертикали
 * @param activeTopicId – id активного топика
 */
export const useMenuItems = (
  projectID: PROJECT_IDS | undefined,
  activeTopicId: StateTopicType['id'] | undefined | null,
) => {
  const topics = useTopicsMenu(projectID);

  return useMemo(
    () =>
      topics.map(({ alias, id, name }) => ({
        url: alias,
        id,
        title: name,
        isActive: activeTopicId === id,
      })),
    [activeTopicId, topics],
  );
};

import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import { fetchGuideCountries } from 'common/redux/pages/guide';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const GuideDesktop = loadable(() => import('desktop/pages/Guide'));
const GuideMobile = loadable(() => import('mobile/pages/Guide'));

/**
 * Роут Путеводителя.
 * https://travel.rambler.ru/guide/
 */
export const guideRoutes = (isMobile: Runtime['isMobile']): IAppRoute => ({
  name: PAGE_TYPE.guide,
  exact: true,
  path: '/guide/',
  render: isMobile ? () => <GuideMobile /> : () => <GuideDesktop />,
  fetchData: async ({ dispatch }) => {
    const pageDownloaderParams = isMobile
      ? ([GuideMobile, 'GuideMobile'] as const)
      : ([GuideDesktop, 'GuideDesktop'] as const);

    await dispatch(fetchTravelCountries());
    await dispatch(fetchGuideCountries());
    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});

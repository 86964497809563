import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { TOPIC_STATUS } from 'config/constants/topics';

/** Ненужные/неактивные топики которые нужно скрыть */
const EXCLUDED_TOPICS: { [projectId in number]: number[] } = {
  [PROJECT_IDS.Woman]: [990],
};

export const filterTopics = (
  topics: StateTopicType[],
  projectId: PROJECT_IDS | undefined,
) => {
  const filteredTopics = topics.filter(
    ({ status, details, id }) =>
      status === TOPIC_STATUS.active &&
      details.level_pos &&
      typeof projectId === 'number' &&
      !EXCLUDED_TOPICS[projectId]?.includes(id),
  ) as StateTopicType[];

  return filteredTopics.sort(
    ({ details: detailsA }, { details: detailsB }) =>
      detailsA.level_pos - detailsB.level_pos,
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRecommendedClusters } from 'api';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { selectPageName } from 'common/redux/appController/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import {
  selectApiConfig,
  selectAdtechScope,
  selectAdtechUid,
  selectIsBot,
  selectRecommendBlockID,
  selectRuid,
  selectRuntime,
  selectUserId,
  selectVariables,
  selectDomainType,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { selectPageTopicName } from 'common/redux/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { RUID_MOCK } from 'config/constants/recommend';
import { createCardData, createRecCardData } from 'utils/createCardData';

import { upsertEntries } from '../../entries';
import { fetchExpertsByClusterId } from '../../experts/asyncs';
import { fetchClustersByManualTag } from '../../manualTags/asyncs';
import { addRecommends } from '../../recommendedClusters';
import { selectRecommendedClustersSessionID } from '../../recommendedClusters/selectors';
import { selectRegionNameByRegionPage } from '../../regions/selectors';

import {
  TAG_CLUSTERS_LENGTH,
  TAG_NAMES_MAPS_BY_WIDGET_TYPE,
} from './constants';
import {
  FetchHotNewsWidgetClustersType,
  FetchTopProjectWidgetType,
  TAG_WIDGET,
} from './typings';
import { getNewsByTopOrTopic } from './utils';

const TOP_LIMIT = 5;

/**
 * Функция загрузки новостей для виджета главного топа.
 * @param projectId - id проекта, для которого грузятся новости;
 * @param topId - тип топа, для которого грузятся новости.
 */
export const fetchTopProjectNews = createAsyncThunk(
  'widgets/fetchTopProjectNews',
  async (
    {
      excludeTopicsIds = [],
      limit = TOP_LIMIT,
      ...props
    }: FetchTopProjectWidgetType,
    { getState, dispatch },
  ) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;
    const api = selectApiConfig(state);
    const runtime = selectRuntime(state);
    const clusterFeed = selectClusterPageClusterFeed(state);

    const { data, error } = await getNewsByTopOrTopic({ api, ...props });

    if (error || !data) {
      throw error;
    }

    const filteredCards = data
      .filter(({ id }) => !clusterFeed.includes(`${id}`))
      .filter(
        ({ topic }) => topic?.alias && !excludeTopicsIds.includes(topic.alias),
      )
      .slice(0, limit)
      .map((entry) => createCardData({ card: entry, runtime }));

    dispatch(upsertEntries(filteredCards));

    return filteredCards.map(({ id }) => id);
  },
);

/**
 * Функция загрузки новостей для виджета определенного типа
 * @param projectId - id вертикали;
 * @param widgetType - тип виджета, для которого происходит загрузка новостей;
 * @param withExpert - флаг, что стоит загружать экспертов для виджета.
 */
export const fetchTagNewsWidgetClusters = createAsyncThunk(
  'widgets/fetchTagNewsWidgetClusters',
  async (
    { projectId, widgetType, withExperts }: FetchHotNewsWidgetClustersType,
    { dispatch },
  ) => {
    const mapToGetWidgetType = TAG_NAMES_MAPS_BY_WIDGET_TYPE[widgetType];
    const clusterLength = TAG_CLUSTERS_LENGTH[widgetType];
    const errorType =
      widgetType === TAG_WIDGET.HotNews ? 'горячих новостей' : 'мнений';
    const manualTag =
      mapToGetWidgetType[projectId] ?? mapToGetWidgetType[PROJECT_IDS.News];

    const data = await dispatch(
      fetchClustersByManualTag({ manualTag, projectId }),
    );

    if (!data?.payload) {
      throw new Error(`Ошибка получения кластеров для виджета ${errorType}`);
    }

    const clusterIds = (data?.payload as CardData['id'][]).slice(
      0,
      clusterLength,
    );

    if (withExperts) {
      await Promise.all(
        clusterIds.map((clusterId) =>
          dispatch(fetchExpertsByClusterId({ clusterId })),
        ),
      );
    }

    return clusterIds;
  },
);

type FetchTopNewsRecPropsType = {
  projectId: number;
  limit: number;
  itemExcludedIds?: CardData['id'][];
  rcmBlockType: RCM_BLOCK_TYPE;
};

/**
 * Функция загрузки рекомендательных новостей
 * @param limit - количественный лимит;
 * @param itemExcludedIds - исключение уже отданных кластеров из выдачи рекомендаций.
 * @param rcmBlockType - алиас, под которым хранится BlockID в сторе
 */
export const fetchTopNewsRec = createAsyncThunk(
  'widgets/fetchTopNewsRec',
  async (props: FetchTopNewsRecPropsType, { getState, dispatch }) => {
    const { limit, itemExcludedIds = [], rcmBlockType } = props;

    const state = getState() as IAppState;

    const variables = selectVariables(state);
    const domainType = selectDomainType(state);
    const projectId = selectProjectId(state);

    const apiConfig = selectApiConfig(state);
    const pageName = selectPageName(state);
    const regionName = selectRegionNameByRegionPage(state);
    const isBot = selectIsBot(state);
    const userId = selectUserId(state);
    const ruid = selectRuid(state);
    const xuid = ruid || (__DEV__ || __DEV_FEATURES__ ? RUID_MOCK : '');
    const category = selectPageTopicName(state);
    const sessionID = selectRecommendedClustersSessionID(rcmBlockType)(state);
    const blockId = selectRecommendBlockID(rcmBlockType)(state);
    const adtechUid = selectAdtechUid(state);
    const adtechScope = selectAdtechScope(state);
    const adtechData = { uid: adtechUid, scope: adtechScope };

    const { data, error } = await getRecommendedClusters({
      blockId,
      xuid,
      itemId: 0,
      limit,
      itemExcludedIds,
      userId,
      isBot,
      sessionID,
      pageName,
      category,
      locationName: regionName,
      apiConfig,
      adtechData,
    });

    if (error || !data) {
      throw error;
    }

    dispatch(addRecommends({ data, rcmBlockType }));

    const recommendedCards = data.recommendations.map((card) =>
      createRecCardData({
        card,
        variables,
        domainType,
        projectId,
        commentsCount: 0,
      }),
    );

    dispatch(upsertEntries(recommendedCards));

    return recommendedCards.map(({ id }) => id);
  },
);

import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const woman: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 455518210,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542287483,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 455518238,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 527556583,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 455518370,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 455518262,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 455518922,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 455518264,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '218',
        },
        begun: 455518342,
      },
      [Banners.ContextRight]: {
        display: 'sspScroll',
        begun: 560473833,
      },
      [Banners.ContextRight2]: {
        display: 'sspScroll',
        begun: 560473835,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '219',
        },
        begun: 455518344,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 455518882,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '353',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '837',
        },
        dir1: {
          [PUIDS.puid61]: '835',
        },
        begun: 455518884,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 455518892,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '219',
        },
        begun: 455875352,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 455519234,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 455518888,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 455518270,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 455518276,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 473294432,
        dir1: {
          [PUIDS.puid61]: '836',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 476543786,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 476104650,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 477431903,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 498566933,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 530719467,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349061,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387014,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'WOMANRAMBLERRU_MAIN',
          puid18: 'WOMANRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498280,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503440,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460429584,
    banners: {
      [Banners.TopBanner]: {
        begun: 460429640,
        ampBegun: 491776187,
        p1: 'bzmeu',
      },
      [Banners.Rich]: {
        begun: 460429730,
        ampBegun: 579334539,
      },
      [Banners.Fullscreen]: {
        begun: 460429718,
      },
      [Banners.Listing1]: {
        begun: 460429720,
      },
      [Banners.Listing2]: {
        begun: 460429722,
      },
      [Banners.Listing3]: {
        begun: 460429724,
      },
      [Banners.Listing4]: {
        begun: 460429726,
      },
      [Banners.Content1]: {
        begun: 460429702,
        ampBegun: 472439364,
        p1: 'bymfg',
      },
      [Banners.Content2]: {
        begun: 460429706,
        ampBegun: 472439630,
        p1: 'bymfi',
      },
      [Banners.Content3]: {
        begun: 460429708,
        ampBegun: 472439650,
        p1: 'bymfk',
      },
      [Banners.Content4]: {
        begun: 460429710,
        ampBegun: 472439672,
        p1: 'bymfm',
      },
      [Banners.Spec]: {
        begun: 460429728,
      },
      [Banners.ContentSpec]: {
        begun: 460429712,
      },
      [Banners.Inpage]: {
        begun: 460429642,
      },
      [Banners.Sponsored]: {
        begun: 460429732,
      },
      [Banners.Footer]: {
        begun: 460429714,
        ampBegun: 472448346,
        p1: 'bymfn',
      },
      [Banners.MarketingContent]: {
        begun: 527556677,
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '430',
        },
        begun: 482104111,
      },
      [Banners.Paid]: {
        begun: 579387016,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'WOMANRAMBLERRU_MAIN',
          puid18: 'WOMANRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498282,
      },
    },
  },
};

import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { selectLoadedRecommendedClusters } from 'common/redux/commonData/widgets/recommendationWidget/selectors';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import {
  selectClusterPage,
  selectClusterPageClusterFeed,
  selectClusterPageTopicId,
} from 'common/redux/pages/cluster/selectors';
import { selectTopicPageTopicId } from 'common/redux/pages/topic/selectors';
import {
  selectOkkoWidgetCountFinishTime,
  selectProjectId,
  selectIsMobile,
  selectOkkoWidgetEnabled,
  selectOkkoWidgetMobilePosition,
  selectVariables,
  selectGigaEnabled,
  selectIsHumanCenteredStrategyStateActivated,
} from 'common/redux/runtime/selectors';
import { PuidsType } from 'config/constants/common';
import { EUR_ID, USD_ID } from 'config/constants/finance';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';
import {
  CONVERTER_ID,
  CROSS_COURSE_ID,
  EXCHANGE_COURSE_ID,
  FORECAST_COURSE_ID,
  CURRENCIES_COURSES_ID,
  DOLLAR_COURSE_ID,
  EURO_COURSE_ID,
  GUIDE_ID,
} from 'config/constants/topics';

import { selectPageName } from '../appController/selectors';
import { selectLocationData } from '../commonData/location/selectors';
import { selectCurrencyPageCurrentCurrencyId } from '../pages/currency/selectors';

import { PUIDS_SELECTORS_BY_PAGENAME } from './config';

/**
 * Селектор, получающий id рубрики на текущей странице рубрики или возвращающий null если рубрики на данной странице нет.
 * Работает и для форматного топика и для старой версии стейта.
 */
export const selectPageTopicId = (state: IAppState): number | null => {
  const pageName = selectPageName(state);
  const currentCurrency = selectCurrencyPageCurrentCurrencyId(state);

  if (pageName === PAGE_TYPE.topic) {
    return selectTopicPageTopicId(state) ?? null;
  }

  if (pageName === PAGE_TYPE.converter) {
    return CONVERTER_ID;
  }

  if (pageName === PAGE_TYPE.exchange) {
    return EXCHANGE_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.cross) {
    return CROSS_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.consensus) {
    return FORECAST_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.currencies) {
    return CURRENCIES_COURSES_ID;
  }

  if (pageName === PAGE_TYPE.currency && currentCurrency === USD_ID) {
    return DOLLAR_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.currency && currentCurrency === EUR_ID) {
    return EURO_COURSE_ID;
  }

  if (pageName === PAGE_TYPE.guide) {
    return GUIDE_ID;
  }

  if (pageName === PAGE_TYPE.cluster) {
    return selectClusterPageTopicId(state);
  }

  return null;
};

/**
 * Селектор, получающий объект рубрики текущий страницы или возвращающий null, если рубрики нет.
 */
export const selectPageTopic = (state: IAppState) =>
  selectPageTopicId(state)
    ? selectTopicById(selectPageTopicId(state))(state)
    : null;

/**
 * Селектор для получения пуидов в зависимости от типа страницы
 */
export const selectPagePuids = (state: IAppState): PuidsType => {
  const pageName = selectPageName(state);
  const selector = PUIDS_SELECTORS_BY_PAGENAME[pageName];

  return selector ? selector(state) : {};
};

export const selectClusterTopic = (state: IAppState) => {
  const clusterPage = selectClusterPage(state);

  return selectTopicById(clusterPage.topicId)(state);
};

export const selectPageTopicName = (state: IAppState) => {
  const pageName = selectPageName(state);

  switch (pageName) {
    case PAGE_TYPE.cluster:
      return selectClusterTopic(state)?.name || '';

    case PAGE_TYPE.topic:
      return selectPageTopic(state)?.name || '';

    default:
      return '';
  }
};

export const selectOkkoWidgetRegions = (index: 1 | 2) => (state: IAppState) => {
  const value =
    selectVariables(state)[`okkoWidgetRegions${index === 1 ? '' : index}`];

  return value ? value.split(',').map((item) => item.trim()) : undefined;
};

export const selectOkkoWidgetRegion = (index: 1 | 2) => (state: IAppState) => {
  const userLocationData = selectLocationData(state);
  const okkoWidgetRegions = selectOkkoWidgetRegions(index)(state);

  const getParentId = (location: LocationData, acc: string[]) => {
    acc.push(location.id);

    if (location.parent) {
      getParentId(location.parent, acc);
    }

    return acc;
  };

  const userLocationIds: string[] = [];

  if (userLocationData) {
    getParentId(userLocationData, userLocationIds);
  }

  return userLocationIds.find((item) => okkoWidgetRegions?.includes(item));
};

export const selectOkkoWidgetType = (state: IAppState) => {
  const isMobile = selectIsMobile(state);
  const okkoWidgetEnabled1 = selectOkkoWidgetEnabled(1)(state);
  const okkoWidgetEnabled2 = selectOkkoWidgetEnabled(2)(state);
  const okkoWidgetRegion1 = selectOkkoWidgetRegion(1)(state);
  const okkoWidgetRegion2 = selectOkkoWidgetRegion(2)(state);
  // const okkoWidgetLink1 = selectOkkoWidgetLink(1)(state);
  // const okkoWidgetLink2 = selectOkkoWidgetLink(2)(state);
  const okkoWidgetCountFinishTime1 = selectOkkoWidgetCountFinishTime(1)(state);
  const okkoWidgetCountFinishTime2 = selectOkkoWidgetCountFinishTime(2)(state);
  const okkoWidgetMobilePosition = selectOkkoWidgetMobilePosition(state);

  const isShowOkkoWidget1 =
    okkoWidgetEnabled1 &&
    okkoWidgetRegion1 &&
    // okkoWidgetLink1 &&
    okkoWidgetCountFinishTime1;
  const isShowOkkoWidget2 =
    okkoWidgetEnabled2 &&
    okkoWidgetRegion2 &&
    // okkoWidgetLink2 &&
    okkoWidgetCountFinishTime2;
  const isShowOkkoWidget = isShowOkkoWidget1 || isShowOkkoWidget2;

  if (!isShowOkkoWidget) return null;

  if (isMobile && !okkoWidgetMobilePosition) return null;

  return isShowOkkoWidget1 ? 1 : 2;
};

export const selectItemExcludedIds = (state: IAppState): string[] => {
  const projectId = selectProjectId(state);
  const clusterFeed = selectClusterPageClusterFeed(state);
  const loadedRecommendsClusters = selectLoadedRecommendedClusters(state);
  // Кластера из виджета Главное вертикали
  const topProjectClusters =
    selectWidgetsTopProjectClusterIDs(projectId)(state);
  // Кластера из виджета Главное сейчас
  const topMainClusters = selectWidgetsTopMainClusterIDs(state);

  return [
    ...clusterFeed,
    ...topProjectClusters,
    ...topMainClusters,
    ...loadedRecommendsClusters,
  ];
};

export const selectIsGigaChatShow = (state: IAppState) => {
  const projectId = selectProjectId(state);
  const pageName = selectPageName(state);
  const gigaEnabled = selectGigaEnabled(state);
  const forceRedesign = selectIsHumanCenteredStrategyStateActivated(state);

  return (
    forceRedesign &&
    projectId === PROJECT_IDS.News &&
    pageName === PAGE_TYPE.home &&
    gigaEnabled
  );
};

import {
  BLOCK_TYPES,
  ENTITY_TYPES,
} from 'common/components/ClusterContentOnDraft/typings';
import { RECORD_ID_REGEXP } from 'common/components/VideoPlayer/hooks/useVideoParams';
import {
  selectIsBannedCommentsTopic,
  selectVariables,
} from 'common/redux/runtime/selectors';

import { getUrlWithUtmParams } from '../getUrlWithUtmParams';
import { getUtmParamsTopic } from '../getUtmParamsTopic';
import { generateClusterUrl, swapUrlToProxyDomain } from '../urlGenerator';

const isLiveVideoFlag = 'data-live="true"';

// Ссылки с хостом 'cdn-store' проксируются на s3
const CHECK_FOR_CDN_STORE = 'cdn-store';

export const CLUSTER_DATA_DEFAULT: ATCluster = {
  body: '',
  body_short: '',
  is_paid: false,
  items_count: 0,
  creation_time: '',
  is_commercial: false,
  gallery: [],
  main_video: undefined,
  is_trash: false,
  id: null,
  title: '',
  long_title: '',
  status: null,
  no_comments: false,
  cluster_type: null,
  image: undefined,
  topic: {} as TopicType,
  annotation: '',
  publication_time: '',
  modification_time: '',
  normalized_title: '',
  comments_count: 0,
  mentions: {
    title: [],
    body: [],
  },
  displayType: undefined,
  draft: {} as RawDraftContentState,
  is_new_draft: false,
};

/**
 * Вспомогательная функция, извлекающая топик из урла кластера.
 * @param url - ссылка на кластер.
 */
const getTopicFromUrl = (url: string) =>
  url.replace(/^http(s)?:\/\/.*?\//gi, '').replace(/\/.*/gi, '');
const isBannedComments = (url: string, variables: Runtime['variables']) =>
  selectIsBannedCommentsTopic(getTopicFromUrl(url))({
    runtime: { variables },
  } as IAppState);

/**
 * Адаптер объекта кластера из рекомендаций в объект данных для карточки
 * @param props.card - объект кластера из рекомендаций;
 * @param props.commentsCount - количество комментариев, отображаемых в карточке;
 * @param props.variables - список переменных, полученных из админки Главной.
 */
export const createRecCardData = ({
  card: {
    item,
    itemID: id,
    itemTitle: title,
    itemPubDate,
    description,
    itemUrl: url,
    itemImage,
    displayType,
    topic,
  },
  variables,
  domainType,
  projectId,
  commentsCount,
}: {
  card: ATRcmType;
  variables: Runtime['variables'];
  domainType: Runtime['domainType'];
  projectId: Runtime['project']['id'];
  commentsCount?: number;
}): CardData => ({
  id: String(id),
  item,
  title,
  normalizedTitle: '',
  description,
  url: swapUrlToProxyDomain({ url, domainType, projectId }),
  image: {
    url: itemImage,
    s3: itemImage.includes(CHECK_FOR_CDN_STORE),
    source: {
      url: '',
      title: '',
    },
  },
  video: {
    url: null,
  },
  displayType,
  topic,
  publicationTime: itemPubDate,
  // Рекоменды не отдают два нижних поля
  creationTime: '',
  modificationTime: '',
  commentsCount,
  noComments: isBannedComments(url, variables),
  resourcesCount: 0,
  isRec: true,
});

type createCardDataType = {
  card: (ATClusterWithUrl | ATCluster | ATCard) & { url?: string };
  runtime: Runtime;
  isAbsoluteUrl?: boolean;
};

/**
 * Адаптер объекта кластера в объект данных для карточки
 * @param card - объект кластера с данными
 * @param runtime - объект рантайма приложения
 * @param isAbsoluteUrl - флаг, делать ли урл абсолютным.
 *  Выводить этот флаг в false КРАЙНЕ нежелательно - все ссылки должны быть в одном формате
 *    и иметь внутри себя домен.
 *  Спилить, когда будет удален ReadAlso.
 */
export const createCardData = ({
  card,
  runtime,
  isAbsoluteUrl = true,
}: createCardDataType): CardData => ({
  id: String(card.id),
  type: card.cluster_type ?? undefined,
  title: card.title,
  normalizedTitle: card.normalized_title ?? '',
  description: card?.long_title,
  url:
    // Если снаружи уже сгенерирован url для кластера, не пытаемся ничего делать
    card.url ??
    generateClusterUrl({
      domainType: runtime.domainType,
      clusterId: String(card.id),
      normalizedTitle: card.normalized_title,
      addDomain: isAbsoluteUrl,
      topic: card.topic,
    }),
  image: {
    url: card.image?.url ?? '',
    width: card.image?.width || null,
    height: card.image?.height || null,
    s3: !!card.image?.s3,
    description: card.image?.description || '',
    id: card.image?.id || null,
    source: {
      url: card.image?.source?.url ?? '',
      title: card.image?.source?.title ?? '',
    },
  },
  displayType: card.displayType,
  topic: card.topic?.name ?? '',
  mainTopicId: card.topic?.id ?? undefined,
  topicUrl: card.topic?.url ?? '',
  topicAlias: card.topic?.alias ?? '',
  annotation: card.annotation ?? '',
  publicationTime: card.publication_time ?? '',
  modificationTime: card.modification_time ?? '',
  creationTime: 'creation_time' in card ? card.creation_time : '',
  // Специально оставил без фоллбечного значения, чтобы не было ошибок рассчета
  commentsCount: card.comments_count,
  noComments:
    card.no_comments ||
    isBannedComments(
      card?.topic?.alias,
      selectVariables({ runtime } as IAppState),
    ),
  resourcesCount: (card as ATCluster).items_count,
  video: {
    duration: card.main_video?.details?.duration ?? 0,
    url: card.main_video?.details?.file_url ?? '',
    preview: card.main_video?.details?.image ?? '',
    recordId:
      card.main_video?.details?.record_id ??
      card.main_video?.embed_code?.match(RECORD_ID_REGEXP)?.[1] ??
      '',
    isLive: card.main_video?.embed_code?.includes(isLiveVideoFlag) ?? false,
    embedCode: card.main_video?.embed_code ?? '',
    videoData: card.main_video?.video_data || null,
  },
  isRec: false,
});

/**
 * Адаптер данных источника кластера в объект данных для карточки
 * @param card - данные источника кластера
 * @param runtime - объект рантайма приложения
 */
export const createCardDataFromClusterSource = (
  clusterData: ATClusterItem,
  runtime: Runtime,
): CardData => {
  const {
    resource_id: resourceId,
    id,
    pubdate,
    title,
    url,
    resource,
  } = clusterData;
  const utmParamsTopic = getUtmParamsTopic(resourceId, runtime);

  return createCardData({
    card: {
      ...CLUSTER_DATA_DEFAULT,
      id,
      publication_time: pubdate,
      title,
      url: getUrlWithUtmParams({
        url,
        params: { source: `r${runtime.project.alias}` },
      }),
      topic: {
        id: resource.id,
        name: resource.title,
        alias: '',
        status: null,
        project_id: null,
        details: null,
        url:
          getUrlWithUtmParams({ url: resource.url, params: utmParamsTopic }) ||
          '',
      },
    },
    runtime,
  });
};

/**
 * Адаптер, расширяющий тип карточки до типа кластера.
 * @param props - объект с данными карточки и уточняющими данными кластера;
 * @param props.card - данные карточки, которые лягут в основу кластера;
 * @param props.annotation - данные аннотации кластера;
 * @param props.longTitle - расширенный заголовок кластера;
 * @param props.draft - драфт кластера, если есть;
 * @param props.gallery - данные о галлерее кластера;
 * @param props.isCommercial - флаг, что кластер коммерческий Лонгрид;
 * @param props.isPaid - флаг, что кластер оплаченный Партнерский материал;
 * @param props.isNewDraft - флаг, что для этого кластера сгенерирован новый формат драфта.
 * @returns Объект кластера.
 */
export const adaptCardDataToClusterData = ({
  card,
  body,
  annotation,
  longTitle,
  draft,
  gallery,
  isCommercial,
  isPaid,
  isNewDraft,
}: {
  card: CardData;
  body?: ATCluster['body'];
  annotation?: ATCluster['annotation'];
  longTitle?: ATCluster['long_title'];
  draft?: ATCluster['draft'];
  gallery?: ATCluster['gallery'];
  isCommercial?: ATCluster['is_commercial'];
  isPaid?: ATCluster['is_paid'];
  isNewDraft?: ATCluster['is_new_draft'];
}): ClusterData => ({
  ...card,
  body: body ?? '',
  annotation: annotation ?? '',
  longTitle: longTitle ?? '',
  resourceId: undefined,
  editorId: null,
  manualTagIds: [],
  autotagIds: [],
  related: [],
  topicIds: [],
  themeIds: [],
  expertIds: [],
  itemIds: [],
  mainItemId: null,
  puids: {},
  draft,
  gallery: gallery ?? [],
  isCommercial: isCommercial ?? false,
  isPaid: isPaid ?? false,
  isNewDraft: isNewDraft ?? false,
});

const IGNORE_DRAFT_BLOCKS = [ENTITY_TYPES.RAdvTurbo];

/**
 * Фильтрация от пустых блоков, чтобы изображение кластера гарантировано вставлялось после первого абзаца текста (ex. 51823661, 51895761)
 * @param block.type – тип блока
 * @param block.text – текст блока
 * @param block.entityRanges – вставки для блока
 */
const isEmptyBlock = ({
  type,
  text,
  entityRanges,
}: RawDraftContentState['blocks'][number]) => {
  const typesForCheck = [BLOCK_TYPES.Unstyled, BLOCK_TYPES.Paragraph];

  return (
    typesForCheck.includes(type as BLOCK_TYPES) &&
    !text.trim() &&
    !entityRanges.length
  );
};
/**
 * Функция фильтрации от блоков драфта которые мы не обрабатываем. На данный момент это RAdvTurbo и пустые блоки.
 * Важно!!! Если он когда нибудь понадобится или без него нельзя будет обойтись нужно будет придумать что нибудь,
 * чтобы он не вставлялся посередине списков и не ломал нумерацию как на кластере 44097221 или 52637339, а также чтобы
 * в кластере 51794435 реклама не вставала в конец кластера @see checkCanInsertBanners
 * @param draft – объект кластера для драфта.
 */
const filterDraftUnusedBlocks = (
  draft: ATCluster['draft'],
): RawDraftContentState => {
  if (!Array.isArray(draft?.blocks)) return draft;

  const newBlocks = draft.blocks.filter((block, index) => {
    const isAdvTurboBlock = block.entityRanges.some(({ key }) =>
      IGNORE_DRAFT_BLOCKS.includes(draft.entityMap[key].type as ENTITY_TYPES),
    );

    if (index === 0) {
      return !isAdvTurboBlock && !isEmptyBlock(block);
    }

    return !isAdvTurboBlock;
  });

  return { ...draft, blocks: newBlocks };
};

/**
 * Расширенный тип карточки.
 * @param cluster - объект кластера с данными;
 * @param runtime - объект рантайма приложения;
 * @param isAbsoluteUrl - флаг, делать ли урл абсолютным @see createCardData
 */
export const createClusterData = (
  cluster: ATCluster,
  runtime: Runtime,
  isAbsoluteUrl?: boolean,
): ClusterData =>
  adaptCardDataToClusterData({
    card: createCardData({ card: cluster, runtime, isAbsoluteUrl }),
    body: cluster.body,
    annotation: cluster.annotation,
    longTitle: cluster.long_title,
    draft: filterDraftUnusedBlocks(cluster.draft),
    gallery: cluster.gallery,
    isCommercial: cluster.is_commercial,
    isPaid: cluster.is_paid,
    isNewDraft: cluster.is_new_draft,
  });

/**
 * Type guard для разделения объекта кластера и объекта карточки (у них разное содержимое)
 * @param cluster - объект кластера или карточки для проверки на тип.
 */
export const isClusterData = (
  cluster: CardData | ClusterData | null,
): cluster is ClusterData => (cluster ? 'body' in cluster : false);

/**
 * Обертка над адаптеров, превращающая весь массив в безопасный массив адаптированных кластеров.
 * @param clusters - массив кластеров;
 * @param runtime - объект рантайма приложения;
 * @param isAbsoluteUrl - флаг, делать ли урл абсолютным @see createCardData
 */
export const normalizeClustersToCardData = (
  clusters: (ATClusterWithUrl | ATCluster | ATCard)[] | undefined | null,
  runtime: Runtime,
  isAbsoluteUrl: boolean = true,
): CardData[] =>
  !Array.isArray(clusters)
    ? []
    : clusters
        .filter((cluster) => cluster)
        .map((cluster) =>
          createCardData({ card: cluster, runtime, isAbsoluteUrl }),
        );

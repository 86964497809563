import { RouteConfig, matchRoutes } from 'react-router-config';

import { selectRuntime } from 'common/redux/runtime/selectors';
import { getMobileRoutes } from 'mobile/routes';

/**
 * Запрос данных для страницы, на которую осуществляется переход
 * @param url - ссылка для перехода
 * @param callback - колбек для выполнения после загрузки данных (например пуш урла в роутер)
 */
export const clientFetchData =
  (url: string, callback?: (route: RouteConfig) => void) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    const runtime = selectRuntime(getState());

    const [{ route, match }] = matchRoutes(
      getMobileRoutes(runtime) as RouteConfig[],
      url,
    );

    await route.fetchData({ dispatch, getState } as AppStore, match);

    if (callback) callback(route);
  };

import { createAsyncThunk } from '@reduxjs/toolkit';
import _uniq from 'lodash.uniq';

import { getCluster, getExpert, getExpertsForCluster } from 'api';
import { selectApiConfig, selectRuntime } from 'common/redux/runtime/selectors';
import { createClusterData } from 'utils/createCardData';

import { upsertEntries } from '../entries';

import { extractExpertsFromCluster } from './utils';

type FetchExpertPropsType = {
  expertAlias: string | undefined;
};

/**
 * Получение данных эксперта.
 * @param props.expertAlias - id эксперта.
 */
export const fetchExpert = createAsyncThunk(
  'fetchExpert',
  async ({ expertAlias }: FetchExpertPropsType, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    if (!expertAlias) {
      throw new Error(`Отсутствует expertAlias: ${expertAlias}`);
    }

    const { error, data: expert } = await getExpert(apiConfig, expertAlias);

    if (error || !expert) {
      throw (
        error ||
        new Error(`Ошибка при получение данных эксперта: ${expertAlias}`)
      );
    }

    return expert;
  },
);

/**
 * Функция загрузки экспертов кластера.
 * @param expertIds - id загружаемых экспертов;
 * @param callback - коллбек, которому возвращаются данные успешно загруженных экспертов.
 */
export const fetchExperts = createAsyncThunk(
  'fetchExperts',
  async (
    {
      expertIds,
      callback,
    }: {
      expertIds: ATExpertType['id'][];
      callback?: (resource: ATExpertType[]) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    // Если экспертов нет, то не дергаем зазря ручку
    if (!expertIds.length) {
      return [];
    }

    const { data: experts, error } = await getExpertsForCluster(api, expertIds);

    if (error || !experts) {
      throw (
        error ||
        new Error(`Ошибка при получении экспертов с id: ${experts.join(', ')}`)
      );
    }

    const filteredExperts = experts.filter(Boolean) as ATExpertType[];

    if (callback) await callback(filteredExperts);

    return filteredExperts;
  },
);

type FetchExpertsByClusterIdType = {
  clusterId: CardData['id'];
};

/**
 * Функция загрузки эксперта по id кластера
 * Нужна, когда ручка не присылает draft из которого достается инфа об expertsIds
 * Например для ручки ручного тега
 * @see getTagNewsByProject
 * @param clusterId - id кластера
 */
export const fetchExpertsByClusterId = createAsyncThunk(
  'widgets/fetchExpertsByClusterId',
  async (
    { clusterId }: FetchExpertsByClusterIdType,
    { dispatch, getState },
  ) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;
    const api = selectApiConfig(state);
    const runtime = selectRuntime(state);

    const { data: rawCluster, error } = await getCluster(api, clusterId);

    if (error || !rawCluster) {
      throw error;
    }

    const cluster: ClusterData = createClusterData(rawCluster, runtime);
    const expertIds = _uniq(extractExpertsFromCluster(rawCluster));

    cluster.expertIds = expertIds;

    dispatch(upsertEntries([cluster]));
    await dispatch(fetchExperts({ expertIds }));

    return expertIds;
  },
);

/**
 * Тип устройств
 */
export enum APP_TYPE {
  /**
   * Версия для десктопных устройств
   */
  desktop = 'desktop',
  /**
   * Версия для лонгрида
   */
  longread = 'longread',
  /**
   * Версия для мобильных устройств
   */
  mobile = 'mobile',
}

/**
 * Функционал для получения наименования устройства в зависимости от спец параметров.
 * @param isMobile - флаг, что это мобильное устройство;
 */
export const getDeviceType = (isMobile: boolean) => {
  if (isMobile) {
    return APP_TYPE.mobile;
  }

  return APP_TYPE.desktop;
};

import {
  CLUSTERS_COUNT_VALUES,
  HUMAN_CENTERED_STRATEGY_SPLIT_VALUES,
} from 'config/constants/splits';

export const mediaTop100ClustersCountParamMapMob = {
  [CLUSTERS_COUNT_VALUES.default]: 'cluster_count_mob_default',
  [CLUSTERS_COUNT_VALUES.as_is]: 'cluster_count_mob_as_is',
  [CLUSTERS_COUNT_VALUES.test_1]: 'cluster_count_mob_test_1',
  [CLUSTERS_COUNT_VALUES.test_2]: 'cluster_count_mob_test_2',
};

export const mediaTop100ClustersCountParamMapDesk = {
  [CLUSTERS_COUNT_VALUES.default]: 'cluster_count_web_default',
  [CLUSTERS_COUNT_VALUES.as_is]: 'cluster_count_web_as_is',
  [CLUSTERS_COUNT_VALUES.test_1]: 'cluster_count_web_test_1',
  [CLUSTERS_COUNT_VALUES.test_2]: 'cluster_count_web_test_2',
};

// NEWS-11896
export const mediaTop100HumanCenteredStrategyDesk = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]:
    'humanCenteredStrategy_web_default',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]: 'humanCenteredStrategy_web_on',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]: 'humanCenteredStrategy_web_off',
};

// NEWS-11896
export const mediaTop100HumanCenteredStrategyMob = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]:
    'humanCenteredStrategy_mob_default',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]: 'humanCenteredStrategy_mob_on',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]: 'humanCenteredStrategy_mob_off',
};

import loadable from '@loadable/component';
import React from 'react';

import { fetchEditor } from 'common/redux/commonData/editors/asyncs';
import { selectEditorId } from 'common/redux/commonData/editors/selectors';
import { setEditorId, setEditorPuids } from 'common/redux/pages/editor';
import { fetchEditorClusters } from 'common/redux/pages/editor/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const EditorDesktop = loadable(() => import('desktop/pages/Editor'));
const EditorMobile = loadable(() => import('mobile/pages/Editor'));

type EditorMatchRoute = {
  editorAlias: string;
};

/**
 * Роут автора/редактора.
 * https://news.rambler.ru/editors/alisa-selezneva/
 */
export const editorRoutes = (
  isMobile: Runtime['isMobile'],
): IAppRoute<EditorMatchRoute> => ({
  name: PAGE_TYPE.editor,
  exact: true,
  path: '/editors/:editorAlias/',
  render: isMobile ? () => <EditorMobile /> : () => <EditorDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { editorAlias } }) => {
    const projectAlias = selectProjectAlias(getState());
    const pageDownloaderParams = isMobile
      ? ([EditorMobile, 'EditorMobile'] as const)
      : ([EditorDesktop, 'EditorDesktop'] as const);

    dispatch(setEditorPuids(projectAlias));

    await dispatch(fetchEditor({ editorAlias }));

    const editorId = selectEditorId(editorAlias)(getState());

    dispatch(setEditorId(editorId));

    await dispatch(fetchEditorClusters({ editorAlias }));

    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});

import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

// CLONE NEWS. Ввиду отсутствия данных, тут - клон news

export const dom: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 428678158,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542286633,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 429663120,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.Storoni]: {
        display: 'ssp',
        begun: 456049144,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 508991615,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 437121492,
      },
      [Banners['240x400']]: {
        display: 'reloadssp',
        begun: 429663110,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 432396664,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 429663112,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '193',
        },
        begun: 432396582,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '194',
        },
        begun: 432396588,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 429663124,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '352',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '831',
        },
        dir1: {
          [PUIDS.puid61]: '829',
        },
        begun: 432396592,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 432396590,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '194',
        },
        begun: 455837634,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 449040872,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 442305842,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 442305826,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 432396574,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 472983170,
        dir1: {
          [PUIDS.puid61]: '830',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 476541394,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 476104576,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 477430601,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 498551543,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 530717425,
      },
      [Banners.NativeInRecs]: {
        // native recommendation
        display: 'sspScroll',
        begun: 579348378,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579386970,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498254,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503434,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 437707674,
    banners: {
      [Banners.TopBanner]: {
        begun: 437708104,
        ampBegun: 491775941,
        p1: 'bzmeq',
      },
      [Banners.Rich]: {
        begun: 437708080,
        ampBegun: 579334537,
      },
      [Banners.Fullscreen]: {
        begun: 437708024,
      },
      [Banners.MarketingContent]: {
        begun: 519856381,
      },
      [Banners.Listing1]: {
        begun: 437707752,
      },
      [Banners.Listing2]: {
        begun: 437707762,
      },
      [Banners.Listing3]: {
        begun: 437707772,
      },
      [Banners.Listing4]: {
        begun: 437707774,
      },
      [Banners.Content1]: {
        begun: 437707778,
        ampBegun: 447405710,
        p1: 'bwxfh',
      },
      [Banners.Content2]: {
        begun: 437707794,
        ampBegun: 473300600,
        p1: 'bymxx',
      },
      [Banners.Content3]: {
        begun: 437707802,
        ampBegun: 473300658,
        p1: 'bymxy',
      },
      [Banners.Content4]: {
        begun: 437707804,
        ampBegun: 473300668,
        p1: 'bymxz',
      },
      [Banners.Spec]: {
        begun: 457396186,
      },
      [Banners.ContentSpec]: {
        begun: 457396208,
      },
      [Banners.Inpage]: {
        begun: 437708070,
      },
      [Banners.Sponsored]: {
        begun: 442332192,
      },
      [Banners.Footer]: {
        begun: 437707812,
        ampBegun: 473300738,
        p1: 'bymya',
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '429',
        },
        begun: 481804693,
      },
      [Banners.Paid]: {
        begun: 579386972,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498256,
      },
    },
  },
};

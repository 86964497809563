import { createAsyncThunk } from '@reduxjs/toolkit';
import _max from 'lodash.max';
import _mean from 'lodash.mean';
import _min from 'lodash.min';

import { getCurrencyRatesFromOuterSources } from 'api';
import { CurrencyDataType } from 'common/components/Finance/typings';
import { selectConverterPageAmount } from 'common/redux/pages/converter/selectors';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import {
  CURRENCY_CHAR_CODE,
  isCurrencyCharCode,
  PERIODS,
  SHORT_PERIODS,
} from 'config/constants/finance';

import { fetchCurrencyRatesFromPeriod } from '../../currencies';
import { generateDateConfig } from '../../currencies/config';
import {
  selectCurrencyByCharCode,
  selectCurrencyRatesByPeriod,
} from '../../currencies/selectors';
import { CURRENCY_SOURCES } from '../exchangeRatesWidget/typings';

import { selectChartWidgetPeriod } from './selectors';
import { ChartDataType } from './typings';

import { setDisabledPeriod, setPeriod } from '.';

type GetRatesPropsType = {
  currencyCharCode: string;
  source: CURRENCY_SOURCES;
  period: PERIODS;
  getState: () => unknown;
  dispatch: AppDispatch;
};

/**
 * Тулза для получения курсов валюты из внешних источников.
 * Курсы из внешних источников никуда не складываются, поэтому
 * для них написана отдельная функция.
 * @param props.currencyCharCode - валюта, по которой делается запрос;
 * @param props.period - период, за который делается запрос;
 * @param props.source - источники, из которых получаются данные;
 * @param props.getState - функция получения данных из стора.
 */
const getRatesByOtherSources = async ({
  currencyCharCode,
  period,
  source,
  getState,
}: GetRatesPropsType) => {
  const { periodOptions } = generateDateConfig();

  const { start, end } = periodOptions[period] || {};
  const apiConfig = selectApiConfig(getState() as IAppState);

  const { data: currencyRates, error } = await getCurrencyRatesFromOuterSources(
    {
      apiConfig,
      charCode: currencyCharCode,
      source,
      start,
      end,
    },
  );

  if (error || !currencyRates) {
    throw (
      error ||
      new Error(
        `Ошибка при получении информации о котировках валюты ${currencyCharCode}`,
      )
    );
  }

  return Object.values(currencyRates)[0];
};

/**
 * Тулза для получения курсов валюты из источника Центробанка.
 * Курсы из центробанка складываются в стор, поэтому
 * для него написана отдельная функция.
 * @param props.currencyCharCode - валюта, по которой делается запрос;
 * @param props.period - период, за который делается запрос;
 * @param props.source - источники, из которых получаются данные;
 * @param props.getState - функция получения данных из стора;
 * @param props.dispatch - функция диспатча данных в стор.
 */
const getRatesByCbr = async ({
  currencyCharCode,
  period,
  source,
  getState,
  dispatch,
}: GetRatesPropsType) => {
  if (!isCurrencyCharCode(currencyCharCode)) {
    // eslint-disable-next-line no-console
    if (__SERVER__ || __DEV__)
      console.error(`Некорректная валюта ${currencyCharCode}`);

    // Тут бы ошибку выбрасывать, но никто её все равно не ловит :с
    return null;
  }

  const fetchResult = await dispatch(
    fetchCurrencyRatesFromPeriod({
      charCode: currencyCharCode,
      period,
      source,
    }),
  );

  if (typeof fetchResult?.payload !== 'object' || fetchResult?.payload === null)
    return [];

  const { payload } = fetchResult;
  if (!('currencyRates' in payload)) return null;

  const rates = (payload as { currencyRates: FullCurrencyRatesType })
    ?.currencyRates;

  if (typeof rates !== 'object') return null;

  /**
   * Вытаскиваем ключи-idшники, из которых состоят списки значений валют.
   */
  const id = Number(Object.keys(rates)?.[0]) ?? null;

  if (id === null)
    throw new Error(
      'loadDataForSingleCourseChart - Данных недостаточно либо они не загрузились',
    );

  const state = getState() as IAppState;

  return selectCurrencyRatesByPeriod(id, period)(state);
};

/**
 * Разводящая функция запроса данных по курсам валют.
 * В зависимости от источника запрашивает те или иные данные.
 * @param props.currencyCharCode - валюта, по которой делается запрос;
 * @param props.period - период, за который делается запрос;
 * @param props.source - источники, из которых получаются данные;
 * @param props.getState - функция получения данных из стора;
 * @param props.dispatch - функция диспатча данных в стор.
 */
const getRates = async (props: GetRatesPropsType) => {
  /**
   * Так как все источники кроме cbr возвращают соверешенно иные типы
   *  id и charCode, то мы их не храним, следовательно получаем данные напрямую.
   */

  if (props.source !== CURRENCY_SOURCES.Centrobank)
    return getRatesByOtherSources(props);

  return getRatesByCbr(props);
};

/**
 * Обертка над разводящей функцией получения курсов валют.
 * Обрабатывает случай, когда данных за текущий период нет,
 * и сразу же инициирует следующий запрос за данными.
 * Дополнительно отмечает в сторе, что данный период не содержит данных,
 * и сразу же перебрасывает юзера на следующий период в списке.
 * @param props.currencyCharCode - валюта, по которой делается запрос;
 * @param props.period - период, за который делается запрос;
 * @param props.source - источники, из которых получаются данные;
 * @param props.getState - функция получения данных из стора;
 * @param props.dispatch - функция диспатча данных в стор;
 * @param periods - массив периодов, по которым ещё можно сделать запрос.
 *  Если периодов не осталось, то функция возвращает пустой массив.
 */
const getRatesUntilLoad = async (
  props: GetRatesPropsType,
  periods?: PERIODS[],
): Promise<APICurrencyRateType[]> => {
  const { period, source, currencyCharCode } = props ?? {};

  if (!period || !source || !currencyCharCode) return [];

  const rates = await getRates(props);

  if (
    rates?.length ||
    !periods?.length ||
    currencyCharCode === CURRENCY_CHAR_CODE.RUB
  )
    return rates ?? [];

  props.dispatch(setDisabledPeriod({ source, period }));

  const firstPeriod = periods[0];
  const lastPeriods = periods.slice(1);

  props.dispatch(setPeriod(firstPeriod));
  props.period = firstPeriod;

  return getRatesUntilLoad(props, lastPeriods);
};

type ReturnDataType = {
  chartData: ChartDataType[];
  minY: number;
  maxY: number;
  change: number;
  average: number;
};

type DataLoaderForCourseChartFabricPropsType = {
  currencyCharCode: string;
  period?: PERIODS;
  source?: CURRENCY_SOURCES;
};

/**
 * Фабрика, создающая асинхронные функции для загрузки данных в график.
 * Сама загружает необходимые валюты за необхоимый период, а юзеру надо лишь сказать - как эти данные обработать.
 * @param name - название функции в редаксе;
 * @param processer - функция-обработчик. Принимает массив массивов значений валют за определенный промежуток времени
 *  и должна вернуть следующие данные:
 *  - массив x-y координат на графике и разницу diff между предыдущей и текущей y точками;
 *  - minY - минимальное значение на графике;
 *  - maxY - максимальное значение на графике;
 *  - average - среднее значение на графике;
 *  - change - разница между первым и последним значением на графике.
 * @returns асинхронную функцию для редакса, которая принимает в себя следующие данные:
 *  @param props.currencyCharCode - массив строк с сокращенными названиями валют типа RUB, USD, EUR;
 *  @param props.period - период, за который надо загрузить данные. Если нет, то таковой берется из стейта;
 *  @param props.source - источник для загруки данных - forex, moex, bcs, cbr. По-умолчанию используется cbr.
 */
export const dataLoaderForCourseChartFabric = (
  name: string,
  processer: (
    rates: APICurrencyRateType[],
  ) => Promise<ReturnDataType> | ReturnDataType,
) =>
  createAsyncThunk(
    name,
    async (
      {
        period: _period,
        source = CURRENCY_SOURCES.Centrobank,
        currencyCharCode,
      }: DataLoaderForCourseChartFabricPropsType,
      { dispatch, getState },
    ) => {
      const period =
        _period ?? selectChartWidgetPeriod(getState() as IAppState);

      if (!period || !currencyCharCode) {
        throw new Error(
          `Неверные данные для получения курса за период: ${period}, ${currencyCharCode}`,
        );
      }

      const currentPeriodIndex = SHORT_PERIODS.findIndex(
        (val) => val === period,
      );

      /**
       * Порядок определен не просто так.
       * Если для текущего периода нет данных,
       *  то периоды начинают считаться вперед,
       *  начиная от текущего.
       * Например, для периодов
       *  неделя
       *  месяц
       *  квартал
       *  год
       *  все время
       *
       * Если не удалось загрузить данные для месяца,
       *  то мы грузим данные за квартал,
       *  после за год, после за все время и только после этого за неделю.
       *
       * Так уж вышло, что данные за более широкий период получить можно
       *  с большим шансом, нежели за более узкий.
       * Единственное, что смущает - набор периодов может состоять не только из тех,
       *  что перечислены выше.
       * Но это уже вопрос дальнейших модификаций графика.
       */
      const periods =
        currentPeriodIndex === -1
          ? undefined
          : [
              ...SHORT_PERIODS.slice(currentPeriodIndex + 1),
              ...SHORT_PERIODS.slice(0, currentPeriodIndex),
            ];

      const rates = await getRatesUntilLoad(
        {
          currencyCharCode,
          period,
          source,
          getState,
          dispatch: dispatch as AppDispatch,
        },
        periods,
      );

      return processer(rates);
    },
  );

/**
 * Вспомогательная тулза для вычисления дополнительной информации по графику.
 * @param chartData - массив данных из графика.
 * @returns
 *  minY - минимальное значение графика;
 *  maxY - максимальное значение графика;
 *  change - разница в процентах между первой и последней точкой в графике;
 *  average - среднее значение графика.
 */
export const calculateStats = (chartData: ChartDataType[]) => {
  const minY = _min(chartData.map((val) => val.y)) ?? 0;
  const maxY = _max(chartData.map((val) => val.y)) ?? 0;
  const lastRate = chartData.length > 0 ? chartData[chartData.length - 1].y : 0;
  const firstRate = chartData.length > 0 ? chartData[0].y : 0;
  const change = (lastRate / firstRate) * 100 - 100;

  const average = _mean(chartData.map((val) => val.y)) ?? 0;

  return {
    minY,
    maxY,
    change,
    average,
  };
};

type APITwoCorsesResultType = {
  firstRates: APICurrencyRateType[];
  secondRates: APICurrencyRateType[];
  firstCurrencyData: CurrencyDataType | undefined;
  secondCurrencyData: CurrencyDataType | undefined;
  amount: number | undefined;
};

/**
 * Фабрика, создающая асинхронные функции для загрузки данных ДВУХ!! валют в график.
 * Сама загружает необходимые валюты за необхоимый период, а юзеру надо лишь сказать - как эти данные обработать.
 * @param name - название функции в редаксе;
 * @param processer - функция-обработчик. Принимает массив массивов значений валют за определенный промежуток времени
 *  и должна вернуть следующие данные:
 *  - массив x-y координат на графике и разницу diff между предыдущей и текущей y точками;
 *  - minY - минимальное значение на графике;
 *  - maxY - максимальное значение на графике;
 *  - average - среднее значение на графике;
 *  - change - разница между первым и последним значением на графике.
 * @returns асинхронную функцию для редакса, которая принимает в себя следующие данные:
 *  @param props.currencyCharCode - массив строк с сокращенными названиями валют типа RUB, USD, EUR;
 *  @param props.period - период, за который надо загрузить данные. Если нет, то таковой берется из стейта;
 *  @param props.source - источник для загруки данных - forex, moex, bcs, cbr. По-умолчанию используется cbr.
 */
export const dataLoaderForTwoCoursesChartFabric = (
  name: string,
  processer: (
    result: APITwoCorsesResultType,
  ) => Promise<ReturnDataType> | ReturnDataType,
) =>
  createAsyncThunk(
    name,
    async (
      {
        period: _period,
        source = CURRENCY_SOURCES.Centrobank,
        currencyCharCode,
      }: DataLoaderForCourseChartFabricPropsType,
      { dispatch, getState },
    ) => {
      const currencyCharCodes = currencyCharCode.split(
        ',',
      ) as CURRENCY_CHAR_CODE[];
      const amount = selectConverterPageAmount(getState() as IAppState);

      const period =
        _period ?? selectChartWidgetPeriod(getState() as IAppState);

      if (!period || currencyCharCodes.length !== 2) {
        throw new Error(
          `Неверные данные для получения курса за период: ${period}, ${currencyCharCodes}`,
        );
      }

      const firstCurrencyData = selectCurrencyByCharCode(currencyCharCodes[0])(
        getState() as IAppState,
      );
      const secondCurrencyData = selectCurrencyByCharCode(currencyCharCodes[1])(
        getState() as IAppState,
      );
      const currentPeriodIndex = SHORT_PERIODS.findIndex(
        (val) => val === period,
      );

      const periods =
        currentPeriodIndex === -1
          ? undefined
          : [
              ...SHORT_PERIODS.slice(currentPeriodIndex + 1),
              ...SHORT_PERIODS.slice(0, currentPeriodIndex),
            ];

      const rates = await Promise.allSettled([
        getRatesUntilLoad(
          {
            currencyCharCode: currencyCharCodes[0],
            period,
            source,
            getState,
            dispatch: dispatch as AppDispatch,
          },
          periods,
        ),

        getRatesUntilLoad(
          {
            currencyCharCode: currencyCharCodes[1],
            period,
            source,
            getState,
            dispatch: dispatch as AppDispatch,
          },
          periods,
        ),
      ]);

      if (rates.some(({ status }) => status === 'rejected')) {
        throw new Error(
          `Не удалось получить данные за период: ${period}, ${currencyCharCodes}`,
        );
      }

      return processer({
        firstRates:
          (rates[0] as PromiseFulfilledResult<APICurrencyRateType[]>)?.value ||
          [],
        secondRates:
          (rates[1] as PromiseFulfilledResult<APICurrencyRateType[]>)?.value ||
          [],
        amount,
        firstCurrencyData,
        secondCurrencyData,
      });
    },
  );

import { html as htmlTemplate, oneLineTrim } from 'common-tags';

import { getPublisherScope } from 'common/hooks/usePublishedAdData';
import { selectProjectAlias, selectRuid } from 'common/redux/runtime/selectors';
import { COUNTERS } from 'config/constants/counters';
import { NEWS_PROJECT_ALIASES } from 'config/constants/projects/constants';

import { top100Wrapper } from './top100Wrapper';
import { getTop100Dataset, getTop100Splits } from './utils';

const counters = COUNTERS as ExtendedCountersType;

/**
 * Инициализация скрипта top100
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=35687355
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39493903&preview=%2F39493903%2F42235465%2Fguide-rambler-top100.pdf
 * @param projectAlias - алиас проекта.
 */
export const initTop100 = (projectAlias: NEWS_PROJECT_ALIASES) => {
  const counter = counters[projectAlias].top100;

  return oneLineTrim(htmlTemplate`
    <script>
      var n = document.getElementsByTagName('script')[0];
      var s = document.createElement('script');
      var f = function() { n.parentNode.insertBefore(s, n); };
      s.type = 'text/javascript';
      s.async = true;
      s.src = "${COUNTERS.resources.counters.top100}";
      s.onload = function() {
        window.dispatchEvent(new CustomEvent('top100-init'));
      };
      if (window.opera === '[object Opera]') {
        document.addEventListener('DOMContentLoaded', f, false);
      } else { f(); }
    </script>
    <noscript><img src="//counter.rambler.ru/top100.cnt?pid=${counter}" aria-hidden="true"></noscript>
  `);
};

/**
 * Функция инициализации топ100 на клиенте.
 * @param state - стейт приложения.
 */
export const initClientTop100 = (state: IAppState) => {
  const projectAlias = selectProjectAlias(state);
  const ruid = selectRuid(state);

  top100Wrapper.init({
    projectIds: [
      COUNTERS[projectAlias].top100,
      COUNTERS.sberPortal.top100,
      COUNTERS.sberStats.top100,
    ],
    pub_id: ruid,
    pub_scope: getPublisherScope(),
    attributes_dataset: getTop100Dataset(),
    splits: getTop100Splits(state),
  });
};

import loadable from '@loadable/component';
import React from 'react';

import { setTagPuids } from 'common/redux/pages/tag';
import { fetchTagClusters, fetchTagInfo } from 'common/redux/pages/tag/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const TagDesktop = loadable(() => import('desktop/pages/Tag'));
const TagMobile = loadable(() => import('mobile/pages/Tag'));

type TagMatchRoute = {
  tagAlias: string;
};

/**
 * Роут тега.
 * https://news.rambler.ru/tag/hotnews/
 */
export const tagRoutes = (
  isMobile: Runtime['isMobile'],
): IAppRoute<TagMatchRoute> => ({
  name: PAGE_TYPE.tag,
  exact: true,
  path: '/tag/:tagAlias/',
  render: isMobile ? () => <TagMobile /> : () => <TagDesktop />,
  fetchData: async (
    { dispatch, getState },
    { params: { tagAlias } },
    queryParams,
  ) => {
    const page = Number(queryParams?.page) || 1;

    const projectAlias = selectProjectAlias(getState());

    dispatch(setTagPuids(projectAlias));

    const promiseList: PromiseListType = [
      dispatch(fetchTagInfo({ tagAlias })),
      dispatch(fetchTagClusters({ tagAlias, page })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? TagMobile : TagDesktop,
        key: Object.keys({ TagDesktop })[0],
      }),
    );
  },
});

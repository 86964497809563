import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const kino: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 507925505,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542288915,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 507926021,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 507962097,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 507946699,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 508007937,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 507947931,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '326',
        },
        begun: 507957139,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '327',
        },
        begun: 507957809,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 507966359,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '356',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '840',
        },
        dir1: {
          [PUIDS.puid61]: '838',
        },
        begun: 507982193,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 508007707,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '327',
        },
        begun: 508855439,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 508008219,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 507993111,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 507950875,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 507952069,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 507960299,
        dir1: {
          [PUIDS.puid61]: '839',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 508855977,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 508856317,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 508856319,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 539518577,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 508856385,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 558707649,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349065,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387068,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'KINORAMBLERRU_MAIN',
          puid18: 'KINORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498276,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503444,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 507925533,
    banners: {
      [Banners.TopBanner]: {
        begun: 508452357,
        ampBegun: 508878657,
        p1: 'carht',
      },
      [Banners.Rich]: {
        begun: 508483509,
        ampBegun: 579334545,
      },
      [Banners.Fullscreen]: {
        begun: 508478697,
      },
      [Banners.Listing1]: {
        begun: 508464363,
      },
      [Banners.Listing2]: {
        begun: 508471605,
      },
      [Banners.Listing3]: {
        begun: 508473585,
      },
      [Banners.Listing4]: {
        begun: 508473611,
      },
      [Banners.Content1]: {
        begun: 508473669,
        ampBegun: 508886357,
        p1: 'carhu',
      },
      [Banners.Content2]: {
        begun: 508474003,
        ampBegun: 508891483,
        p1: 'carhv',
      },
      [Banners.Content3]: {
        begun: 508474377,
        ampBegun: 508896623,
        p1: 'carhw',
      },
      [Banners.Content4]: {
        begun: 508474367,
        ampBegun: 508896649,
        p1: 'carhx',
      },
      [Banners.Spec]: {
        begun: 508488247,
      },
      [Banners.ContentSpec]: {
        begun: 508492879,
      },
      [Banners.Inpage]: {
        begun: 508474975,
      },
      [Banners.Sponsored]: {
        begun: 508486275,
      },
      [Banners.Footer]: {
        begun: 508477649,
        ampBegun: 508896671,
        p1: 'carhy',
      },
      [Banners.MarketingContent]: {
        begun: 539522451,
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '431',
        },
        begun: 508574487,
      },
      [Banners.Paid]: {
        begun: 579387072,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'KINORAMBLERRU_MAIN',
          puid18: 'KINORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498278,
      },
    },
  },
};

type CreateNodeType = {
  tagName?: keyof HTMLElementTagNameMap;
  type?: string;
  alt?: string;
  src: string;
  root?: string;
  callback?: (() => void) & { done?: boolean };
  id?: string;
  isHidden?: boolean;
};

/**
 * Создает ноду и вставляет ее перед элементом или первым скриптом на странице
 * @param params - параметры для создания ноды
 * @param params.tagName - тег ноды
 * @param params.type - тип скрипта для ноды скрипта
 * @param params.alt - атрибут для ноды
 * @param params.src - атрибут src у будущей ноды
 * @param params.root - нода, рядом с которой встанет скрипт
 * @param params.callback - колбек, срабатывающий после загрузки данных от ноды
 * @param params.id - id  тега
 * @param params.isHidden - если true, то добавляет атрибут aria-hidden
 */
export const createNode = (params: CreateNodeType) => {
  let parent: Node | Element | null = null;

  const tagName = params.tagName || 'script';

  const elem = document.createElement(tagName);

  (elem as HTMLImageElement).src = params.src;

  if (params.id) {
    elem.id = params.id;
  }

  if (params.alt) {
    (elem as HTMLImageElement).alt = params.alt;
  }

  if (params.type && params.tagName === 'script') {
    (elem as HTMLScriptElement).type = params.type;
  }

  if (params.tagName === 'script') {
    if (params.root) {
      parent = document.querySelector(params.root);
    }

    if (!parent) {
      parent = document.querySelector('script')?.parentNode || document.head;
    }

    (elem as HTMLScriptElement).async = true;
  } else {
    parent = document.body;
    elem.style.position = 'absolute';
    elem.style.width = '0px';
    elem.style.height = '0px';
    elem.style.top = '-1px';
    elem.style.left = '-1px';
  }

  if (params.isHidden) {
    elem.setAttribute('aria-hidden', 'true');
  }

  const append = () => {
    parent?.insertBefore(elem, parent.firstChild);
  };

  if (params.callback) {
    const callback = () => {
      /**
        Странное место, так как readyState и onreadystatechange есть только
          у объектов Document согласно документации:
          @see https://html.spec.whatwg.org/multipage/indices.html#event-readystatechange
       
          Во всех остальных случаях коллбек никто не вызывает.
       */
      const state = (elem as unknown as Document).readyState;

      if (
        params.callback &&
        !params.callback.done &&
        (!state || /loaded|complete/.test(state))
      ) {
        params.callback.done = true;
        params.callback();
      }
    };

    (elem as unknown as Document).onreadystatechange = callback;
    elem.onload = callback;
  }

  if (window.opera === '[object Opera]') {
    elem.addEventListener('DOMContentLoaded', append, false);
  } else {
    append();
  }
};

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setBannerIsBranding } from 'common/redux/appController';
import Banners from 'config/constants/banner/banners';
import { brandCheckerCore } from 'utils/brandingChecker';

import { IResult } from '../typings';

const CATH_WINDOW_BEGUN_INTERVAL = 200;

/**
 * Хук для определения viewType баннера
 */
export const useViewType = (bannerName: Banners) => {
  const dispatch = useDispatch();
  const [viewTypes, setViewTypes] = useState('');
  const [begun, setBegun] = useState<Window['Begun'] | null>(null);

  useEffect(() => {
    let BegunTimerId: NodeJS.Timeout;

    if (!begun) {
      BegunTimerId = setInterval(() => {
        if (window && window.Begun) {
          setBegun(window.Begun);
        }
      }, CATH_WINDOW_BEGUN_INTERVAL);
    }

    if (begun) {
      clearInterval(BegunTimerId!);

      begun.Autocontext?.Callbacks.register({
        block: {
          // Работа с внешним API, интерфейс не определен, поэтому any
          drawComplete: (result: IResult) => {
            setViewTypes(result.viewTypes!);
            brandCheckerCore(result);
          },
        },
      });
    }

    return () => {
      if (begun) {
        begun.Autocontext?.Callbacks?.unregister('block', 'drawComplete');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [begun]);

  useEffect(() => {
    const isBranding =
      viewTypes?.includes('Branding') &&
      [Banners.TopBanner, Banners.Billboard].includes(bannerName);

    if (isBranding) {
      dispatch(setBannerIsBranding({ bannerName, isBranding }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewTypes, bannerName]);
};

import cn from 'classnames';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { OptionalDebugger } from 'common/components/Debugger';
import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { Footer } from 'common/components/FooterDesk';
import { PageSwitch } from 'common/components/PageSwitch';
import { AdProvider } from 'common/components/RamblerComponents/RamblerAd';
import { OrganizationInfoSchema } from 'common/components/schemaOrg/OrganizationInfo';
import { TopicsMenu } from 'common/components/TopicsMenu';
import { TopicsMenuRedesign } from 'common/components/TopicsMenu/Redesign';
import { useRoutes } from 'common/hooks/pages/useRoutes';
import { useAutoRefresh } from 'common/hooks/useAutoRefresh';
import { usePublisherAdData } from 'common/hooks/usePublishedAdData';
import { useTop100User } from 'common/hooks/useTop100User';
import { useYMUserParams } from 'common/hooks/useYMUserParams';
import { selectPageName } from 'common/redux/appController/selectors';
import { fetchLocation } from 'common/redux/commonData/location';
import {
  selectBanners,
  selectIsHumanCenteredStrategyStateActivated,
  selectIsDebug,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { selectPagePuids, selectPageTopicId } from 'common/redux/selectors';
import Banners from 'config/constants/banner/banners';
import { PAGE_TYPE } from 'config/constants/routerName';
import { Header } from 'desktop/components/Header';
import { ScrollbackDesk } from 'desktop/components/ScrollbackDesk';
import { ContainerWidgets } from 'desktop/pages/Converter/components/ContainerWidgets';
import { useAppDispatch } from 'store/hooks';

import { BackgroundBulbs } from './components/BackgroundBulbs';
import { WidgetsStatusContextProvider } from './components/WidgetsStatusProvider';
import { getDesktopRoutes } from './routes';

import s from './styles.module.css';

const SPECIAL_FINANCE_PAGES = [
  PAGE_TYPE.converter,
  PAGE_TYPE.exchange,
  PAGE_TYPE.consensus,
  PAGE_TYPE.cross,
  PAGE_TYPE.currencies,
  PAGE_TYPE.currency,
  PAGE_TYPE.banks,
];

const AD_STYLES = {
  order: -1,
};

export const DesktopApp = memo(() => {
  const dispatch = useAppDispatch();

  const projectId = useSelector(selectProjectId);
  const puids = useSelector(selectPagePuids, shallowEqual);
  const pageName = useSelector(selectPageName);
  const topicId = useSelector(selectPageTopicId);
  const isDebug = useSelector(selectIsDebug);
  const banners = useSelector(selectBanners, shallowEqual);
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const [statuses, setStatuses] = useState<Record<string, boolean>>({});

  const pageRef = useRef(null);

  const { publisherId, publisherScope } = usePublisherAdData();

  const isClusterPage = [PAGE_TYPE.cluster, PAGE_TYPE.clusterComments].includes(
    pageName,
  );
  const isSpecialFinancePages = SPECIAL_FINANCE_PAGES.includes(pageName);

  const routes = useRoutes(getDesktopRoutes);

  const TopicsComponent = forceRedesign ? TopicsMenuRedesign : TopicsMenu;

  useTop100User();
  useAutoRefresh();
  useYMUserParams();

  const value = useMemo(() => {
    return {
      setInitedScript: (url: string) => {
        setStatuses((prevValue) => ({ ...prevValue, ...{ [url]: true } }));

        return statuses;
      },
      statuses,
    };
  }, [statuses]);

  useEffect(() => {
    const fetchLocationPromise = dispatch(fetchLocation());

    return () => {
      fetchLocationPromise?.abort();
    };
  }, [dispatch, pageName]);

  return (
    <AdProvider
      publisherId={publisherId}
      publisherScope={publisherScope}
      padId={banners.BEGUN_PAD_ID}
      isDebug={isDebug}
    >
      {/* Временно отключен. https://jira.rambler-co.ru/browse/NEWS-11207 */}
      {/* <PushwooshPopup pageName={pageName} /> */}

      <WidgetsStatusContextProvider value={value}>
        <OptionalDebugger />
        <OrganizationInfoSchema />
        <Header puids={puids} />
        {forceRedesign && <BackgroundBulbs />}

        <div className={s.billboardContainer}>
          <Ad
            name={Banners.Billboard}
            puids={puids}
            style={AD_STYLES}
            className={s.billboard}
            isLazy={false}
          />
        </div>

        <div className={s.showUp}>
          <Ad name={Banners.ShowUp} puids={puids} />
        </div>

        <div className={cn(s.rootContainer, 'commercial-branding')}>
          {!isClusterPage && (
            <TopicsComponent
              projectId={projectId}
              activeTopicId={topicId}
              className={s.tabs}
            />
          )}

          {isSpecialFinancePages && (
            <ErrorBoundary componentName="ContainerWidgets">
              <ContainerWidgets />
            </ErrorBoundary>
          )}

          <div ref={pageRef} className={s.root}>
            <PageSwitch>{routes}</PageSwitch>
          </div>
        </div>

        <ScrollbackDesk elementRef={pageRef} />
        <Footer />
      </WidgetsStatusContextProvider>
    </AdProvider>
  );
});

// Временно закомментировано до момента выяснения скачков памяти на проде
// export default withProfiler(App, {
//   name: 'Desktop App',
//   includeUpdates: false,
// });

import { useRef, useEffect, useCallback, RefObject, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useObserver } from 'common/hooks/useObserver';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectRecommendedClusterById,
  selectRecommendedClustersByBlockType,
} from 'common/redux/commonData/recommendedClusters/selectors';
import { selectClusterPageClusterIdByIndex } from 'common/redux/pages/cluster/selectors';
import {
  selectIsMobile,
  selectRecommendBlockID,
} from 'common/redux/runtime/selectors';
import { PAGE_TYPE } from 'config/constants/routerName';
import {
  RCMblockInit,
  RCMFullTextShow,
  RCMpageView,
  RCMhubPageView,
  RCMshow,
  RCMclick,
  RCMsetMeta,
} from 'utils/counters/atdRecommender';

/**
 * Хук отправки события об открытии страницы.
 * Внутри определяется, отправить Pageview (только для кластера) или Hubpageview
 */
export const usePageViewAtd = () => {
  const pageName = useSelector(selectPageName);
  const feedListFirstClusterId = useSelector(
    selectClusterPageClusterIdByIndex(0),
  );

  useEffect(() => {
    const { href } = window.location;

    if (href && pageName !== PAGE_TYPE.cluster) {
      RCMhubPageView(href);
    } else {
      RCMpageView(Number(feedListFirstClusterId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export enum RCM_BLOCK_TYPE {
  clusterFeedDesktop = 'clusterFeedDesktop',
  clusterFeedMobile = 'clusterFeedMobile',
  desktopWidget = 'desktopWidget',
  homeDesktop = 'homeDesktop',
  homeMobile = 'homeMobile',
  mobileWidget = 'mobileWidget',
  regionDesktop = 'regionDesktop',
  regionMobile = 'regionMobile',
  rnetHorizontalDesktop = 'rnetHorizontalDesktop',
  rnetHorizontalMobile = 'rnetHorizontalMobile',
  rnetVertical5Desktop = 'rnetVertical5Desktop',
  rnetVertical1Desktop = 'rnetVertical1Desktop',
  topicDesktop = 'topicDesktop',
  topicMobile = 'topicMobile',
  topNewsWidgetDesktop = 'topNewsWidgetDesktop',
  topNowDesktop = 'topNowDesktop',
}

/**
 * Хук отправки события BlockInit
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=48183873#id-%D0%A0%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0-%D0%98%D0%BD%D0%B8%D1%86%D0%B8%D0%B0%D1%86%D0%B8%D1%8F%D0%B1%D0%BB%D0%BE%D0%BA%D0%B0
 * @param rcmBlockType - тип блока, который инициализируется на странице
 */
export const useBlockInitAtd = (
  rcmBlockType: RCM_BLOCK_TYPE,
  clusterID?: CardData['id'],
) => {
  const feedListFirstClusterId = useSelector(
    selectClusterPageClusterIdByIndex(0),
  );
  const blockId = useSelector(selectRecommendBlockID(rcmBlockType));

  useEffect(() => {
    switch (rcmBlockType) {
      case RCM_BLOCK_TYPE.rnetHorizontalDesktop: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.rnetHorizontalMobile: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.rnetVertical1Desktop: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.rnetVertical5Desktop: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.clusterFeedMobile: {
        RCMblockInit(blockId, feedListFirstClusterId);
        break;
      }

      case RCM_BLOCK_TYPE.clusterFeedDesktop: {
        RCMblockInit(blockId, feedListFirstClusterId);
        break;
      }

      case RCM_BLOCK_TYPE.topNowDesktop: {
        RCMblockInit(blockId, clusterID);
        break;
      }

      case RCM_BLOCK_TYPE.homeDesktop: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.topicDesktop: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.homeMobile: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.topicMobile: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.regionDesktop: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.regionMobile: {
        RCMblockInit(blockId);
        break;
      }

      case RCM_BLOCK_TYPE.topNewsWidgetDesktop: {
        RCMblockInit(blockId, clusterID);
        break;
      }

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type UseArticleFullTextShowType = {
  clusterId: ClusterData['id'] | undefined;
  position: number;
  ref: RefObject<Element>;
};

/**
 * Хук отправки события FulltextShow для 2+ кластера в бесконечном скролле.
 * Отправка события происходит один раз после смены урла
 * Для корректной отправки события clusterId переданный в хук и clusterId в урле должны совпадать
 * @see https://recsys.pages.rambler-co.ru/recommender/js/docs/functions/rcm.html#rcm-fulltextShow
 * @param clusterID - ID кластера для отправки.
 * @param position - номер в ленте бесконечного скролла
 * @param ref - ref ссылка на заголовок, за появлением которого нужно наблюдать
 */
export const useArticleFullTextShow = ({
  clusterId,
  position,
  ref,
}: UseArticleFullTextShowType) => {
  const location = useLocation();

  const isMobile = useSelector(selectIsMobile);
  const rcmKey = isMobile
    ? RCM_BLOCK_TYPE.clusterFeedMobile
    : RCM_BLOCK_TYPE.clusterFeedDesktop;
  const blockId = useSelector(selectRecommendBlockID(rcmKey));
  const recommendationsList = useSelector(
    selectRecommendedClustersByBlockType(rcmKey),
    shallowEqual,
  );
  const firstClusterId = useSelector(selectClusterPageClusterIdByIndex(0));

  const sendedIds = useRef<string[]>([]);

  const urlClusterId = location?.pathname?.split('/')?.[2]?.split('-')?.[0];

  useEffect(() => {
    if (position > 0) {
      const callback = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver,
      ) => {
        if (entries[0]?.isIntersecting) {
          const isSended = clusterId
            ? sendedIds.current?.includes(clusterId)
            : true;
          const recommendItem = recommendationsList.find(
            ({ itemID }) => itemID === clusterId,
          );

          if (recommendItem && !isSended && clusterId === urlClusterId) {
            RCMFullTextShow({
              contextItemId: `${firstClusterId}`,
              blockId,
              item: recommendItem.item,
              position: position - 1,
            });

            sendedIds.current?.push(clusterId);
          }

          observer.disconnect();
        }
      };

      const options = {
        threshold: 1.0,
      };

      const observer = new IntersectionObserver(callback, options);

      if (ref.current) {
        observer.observe(ref.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlClusterId]);
};

/** Значения срабатывания наблюдателя (не проценты) */
const INTERSECTION_RATIO = 0.45;

export enum EVENT_NAME {
  click,
  show,
}

/**
 * Хук сбора метрик с любого рекомендательного блока, вставленного в страницу
 * Внутри определяется отправка в rcm 'show' и 'click'
 * @param rcmKey - ключ, по которому храним blockID в конфигурации (topNow например)
 * @param clusterID - id кластера рекомендации
 * @param position - позиция рекомендации внутри блока с рекомендациями
 * @param contextItemId - идентификатор страницы, на которой используется блок
 * (clusterID, pageName...)
 */
export const useRcmBlock = <T extends Element>({
  rcmKey,
  clusterID,
  position,
  contextItemId = typeof window !== 'undefined' ? window.location.href : '/',
}: {
  rcmKey: RCM_BLOCK_TYPE;
  clusterID: CardData['id'] | null;
  position: number;
  contextItemId?: string;
}) => {
  const location = useLocation();

  // Добавлено состояние для отправки show при SPA переходе
  const [readyToShow, setReadyToShow] = useState(true);

  const rcmBlockID = useSelector(selectRecommendBlockID(rcmKey));
  const rcmCluster = useSelector(
    selectRecommendedClusterById(String(clusterID)),
    shallowEqual,
  );

  const isShow = useRef(false);
  const isClick = useRef(false);

  const sendEvent = useCallback(
    (eventName: EVENT_NAME) => {
      if (!rcmCluster?.item) {
        if (__DEV__) {
          console.warn(
            `RCM кластер с id ${clusterID} не найден в рекомендациях`,
          );
        }

        return;
      }

      switch (eventName) {
        case EVENT_NAME.show: {
          RCMshow({
            blockId: rcmBlockID,
            contextItemId,
            item: rcmCluster.item,
            position,
          });
          break;
        }

        case EVENT_NAME.click: {
          RCMclick({
            blockId: rcmBlockID,
            contextItemId,
            item: rcmCluster.item,
            position,
          });
          break;
        }

        default:
          break;
      }
    },
    [contextItemId, clusterID, rcmCluster, rcmBlockID, position],
  );

  const callback = useCallback(() => {
    if (readyToShow && !isShow.current) {
      isShow.current = true;
      setReadyToShow(false);
      sendEvent(EVENT_NAME.show);
    }
  }, [readyToShow, sendEvent]);

  const ref = useObserver<T>({
    callback: isShow.current ? null : callback,
    observerConfig: { threshold: INTERSECTION_RATIO },
  });

  const onClick = useCallback(() => {
    if (!isClick.current) {
      isClick.current = true;
      sendEvent(EVENT_NAME.click);
    }
  }, [sendEvent]);

  useEffect(() => {
    if (isShow.current) {
      isShow.current = false;
      setReadyToShow(true);
    }
  }, [location.pathname]);

  return { ref, onClick };
};

export enum USER_STATE {
  login = 'login',
  logout = 'logout',
}

export type UserStateType = 'login' | 'logout';

/**
 * Хук для отправки setMeta при логине/логауте
 */
export const useRCMsetMeta = () => {
  const [metaState, setMetaState] = useState({ userId: '', userState: '' });

  useEffect(() => {
    const { userId, userState } = metaState;

    const isLogin = userState === USER_STATE.login && userId !== '';
    const isLogout = userState === USER_STATE.logout;

    if (isLogin || isLogout) {
      RCMsetMeta(userId);
    }
  }, [metaState]);

  return { setMetaState };
};

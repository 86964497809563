import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const finance: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 428294616,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542290765,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 429663174,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 527552667,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 446876294,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 429344410,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 429663172,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 429663166,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '162',
        },
        begun: 437940934,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '163',
        },
        begun: 437940936,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 429663176,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '358',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '834',
        },
        dir1: {
          [PUIDS.puid61]: '832',
        },
        begun: 437940892,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 437940876,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '162',
        },
        begun: 455837832,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 449040896,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 446876296,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 446876292,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 430560764,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 533524341,
        dir1: {
          [PUIDS.puid61]: '833',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 476547216,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 437940860,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 477432209,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 498576857,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 530720663,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349057,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579386974,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'FINANCERAMBLERRU_MAIN',
          puid18: 'FINANCERAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498238,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503436,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460311650,
    banners: {
      [Banners.TopBanner]: {
        begun: 460313229,
        ampBegun: 492601857,
        p1: 'bzpkk',
      },
      [Banners.Rich]: {
        begun: 460313235,
        ampBegun: 579334541,
      },
      [Banners.Fullscreen]: {
        begun: 460313233,
      },
      [Banners.Listing1]: {
        begun: 460313209,
      },
      [Banners.Listing2]: {
        begun: 460313211,
      },
      [Banners.Listing3]: {
        begun: 460313213,
      },
      [Banners.Listing4]: {
        begun: 460313215,
      },
      [Banners.Content1]: {
        begun: 460313217,
        ampBegun: 483290825,
        p1: 'bzaei',
      },
      [Banners.Content2]: {
        begun: 460313219,
        ampBegun: 483291647,
        p1: 'bzaej',
      },
      [Banners.Content3]: {
        begun: 460313221,
        ampBegun: 483293461,
        p1: 'bzaek',
      },
      [Banners.Content4]: {
        begun: 460313223,
        ampBegun: 483294771,
        p1: 'bzael',
      },
      [Banners.Spec]: {
        begun: 461254106,
      },
      [Banners.ContentSpec]: {
        begun: 461254108,
      },
      [Banners.Inpage]: {
        begun: 460313231,
      },
      [Banners.Sponsored]: {
        begun: 460313227,
      },
      [Banners.Footer]: {
        begun: 460313225,
        ampBegun: 483296207,
        p1: 'bzaem',
      },
      [Banners.MarketingContent]: {
        begun: 527556087,
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '435',
        },
        begun: 489421273,
      },
      [Banners.Paid]: {
        begun: 579386976,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'FINANCERAMBLERRU_MAIN',
          puid18: 'FINANCERAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498240,
      },
    },
  },
};

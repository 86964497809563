import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCommentsByClusterID, getRecommendedClusters } from 'api';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectAdtech,
  selectApiConfig,
  selectDomainType,
  selectIsHumanCenteredStrategyStateActivated,
  selectIsBot,
  selectProjectId,
  selectRuid,
  selectUserId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { selectPageTopic } from 'common/redux/selectors';
import { RUID_MOCK } from 'config/constants/recommend';
import { createRecCardData } from 'utils/createCardData';

import { addManyEntries } from '../../entries';
import { addRecommends } from '../../recommendedClusters';

const FETCHING_RCM_LIMIT = 5;
const FETCHING_RCM_REDESIGN_LIMIT = 7;

type FetchRecommendedNewsPropsType = {
  clusterId: CardData['id'];
  itemExcludedIds: CardData['id'][];
};

/**
 * Функция загрузки новостей для виджета рекоммендаций.
 * @param clusterID - id кластера, для которого грузятся рекоммендации;
 * @param itemExcludedIds - список id кластеров, которые будут исключены из выдачи рекоммендаций.
 */
export const fetchRecommendedNews = createAsyncThunk(
  'widgets/fetchRecommendedNews',
  async (
    { clusterId, itemExcludedIds }: FetchRecommendedNewsPropsType,
    { getState, dispatch },
  ) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;

    const variables = selectVariables(state);
    const domainType = selectDomainType(state);
    const projectId = selectProjectId(state);
    const forceRedesign = selectIsHumanCenteredStrategyStateActivated(state);
    const isBot = selectIsBot(state);
    const adtech = selectAdtech(state);
    const userId = selectUserId(state);
    const ruid = selectRuid(state);
    const pageTopic = selectPageTopic(state);
    const apiConfig = selectApiConfig(state);
    const pageName = selectPageName(state);

    const blockId = adtech.recommendBlockID[RCM_BLOCK_TYPE.topNowDesktop];

    const { data, error } = await getRecommendedClusters({
      blockId,
      xuid: ruid || (__DEV__ ? RUID_MOCK : ''),
      itemId: clusterId,
      itemExcludedIds,
      limit: forceRedesign ? FETCHING_RCM_REDESIGN_LIMIT : FETCHING_RCM_LIMIT,
      userId,
      pageName,
      isBot,
      category: pageTopic?.name,
      apiConfig,
    });

    if (error || !data) {
      throw error;
    }

    dispatch(
      addRecommends({ data, rcmBlockType: RCM_BLOCK_TYPE.topNowDesktop }),
    );

    const clusterIds = data.recommendations.map((item) => item.itemID);

    const { data: comments, error: commentsError } =
      await getCommentsByClusterID(apiConfig, clusterIds);

    if (commentsError || !comments) {
      throw commentsError;
    }

    const cards = data.recommendations.map((entry, idx) =>
      createRecCardData({
        card: entry,
        variables,
        domainType,
        projectId,
        commentsCount: comments[idx].comments_count,
      }),
    );

    dispatch(addManyEntries(cards));

    // slice оставляем тк ручка лагает и иногда присылает больше рекомендаций чем запрашиваем
    return cards
      .map(({ id }) => id)
      .slice(
        0,
        forceRedesign ? FETCHING_RCM_REDESIGN_LIMIT : FETCHING_RCM_LIMIT,
      );
  },
);

import { combineReducers } from '@reduxjs/toolkit';

import { autotagsReducer } from './autotags';
import { bankRegionsReducer } from './bankRegions';
import { banksReducer } from './banks';
import { currenciesReducer } from './currencies';
import { editorsReducer } from './editors';
import { entriesReducer } from './entries';
import { expertsReducer } from './experts';
import { headerWidgetsReducer } from './headerWidgets';
import { itemsReducer } from './items';
import { locationReducer } from './location';
import { manualTagsReducer } from './manualTags';
import { recommendedClustersReducer } from './recommendedClusters';
import { regionsReducer } from './regions';
import { resourcesReducer } from './resources';
import { themesReducer } from './themes';
import { topicsReducer } from './topics';
import { travelCitiesReducer } from './travelCities';
import { travelCountriesReducer } from './travelCountries';
import { userReducer } from './user';
import { widgetsReducer } from './widgets';

export const commonDataReducer = combineReducers({
  autotags: autotagsReducer,
  bankRegions: bankRegionsReducer,
  banks: banksReducer,
  currencies: currenciesReducer,
  editors: editorsReducer,
  entries: entriesReducer,
  experts: expertsReducer,
  headerWidgets: headerWidgetsReducer,
  items: itemsReducer,
  location: locationReducer,
  manualTags: manualTagsReducer,
  recommendedClusters: recommendedClustersReducer,
  regions: regionsReducer,
  resources: resourcesReducer,
  themes: themesReducer,
  topics: topicsReducer,
  travelCities: travelCitiesReducer,
  travelCountries: travelCountriesReducer,
  user: userReducer,
  widgets: widgetsReducer,
});

import _pick from 'lodash.pick';

import { PUIDS, PuidsType } from 'config/constants/common';

/**
 * @param text - проверяемый на вставку видео через iframe текст
 */
export const checkIframeInText = (text: string) => text.includes('iframe');

// Puids, которые нужно передавать при вставке видео EaglePlatform
export const EAGLEPLATFORM_PUIDS: { [puid in string]: boolean } = {
  puid6: true,
  puid15: true,
  puid59: true,
  puid62: true,
  puid48: true,
};

type PuidsForEaglePlatformType = {
  puids: PuidsType | undefined;
  newPlayer?: boolean;
  newPlayerStr?: boolean;
};

/**
 * Функция, возвращающая строку или объект с пуидами для видео EaglePlatform
 * @param puids - рекламные пуиды
 * @param newPlayer - флаг, что пуиды нужны для нового плеера
 * @param newPlayerStr - флаг, что пуиды нужны для нового плеера в формате строки
 */
export const getPuidsStrForEaglePlatform = ({
  puids,
  newPlayer = false,
  newPlayerStr = false,
}: PuidsForEaglePlatformType): string | PuidsType | undefined => {
  const puidsArr: string[] = [];

  if (puids && newPlayer && !newPlayerStr) {
    return _pick(puids, Object.keys(EAGLEPLATFORM_PUIDS));
  }

  if (puids) {
    Object.keys(puids).map(
      (puid) =>
        EAGLEPLATFORM_PUIDS[puid] &&
        puidsArr.push(`"${puid}":"${puids[puid as PUIDS]}"`),
    );

    const puidsStr = puidsArr.join(',');

    if (newPlayer && newPlayerStr) {
      const puidsString = encodeURIComponent(`{${puidsStr}}`);

      return puidsArr.length ? `&sspJparams=${puidsString}` : '';
    }

    return puidsArr.length ? `&jparams={${encodeURIComponent(puidsStr)}}` : '';
  }

  return newPlayer ? undefined : '';
};

/**
 * Функция преобразования iframe с видео
 * @param text - текст в котором есть видео iframe
 * @param puids - рекламные пуиды
 * @returns - текст с проставленным ad_template_id и puids в видео iframe
 */
export const changeVideoIframe = (text: string, puids?: PuidsType) => {
  const srcRegexp = new RegExp(/src\s*=\s*"(.+?)"/);
  const iframeSrc = text.match(srcRegexp);

  const puidsStr = getPuidsStrForEaglePlatform({ puids });
  const formattedText = `${puidsStr}`;

  return iframeSrc
    ? text.replace(iframeSrc[1], `${iframeSrc[1]}${formattedText}`)
    : text;
};

import cn from 'classnames';
import React, { useMemo } from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { useTopicsMenuReachGoal } from 'common/components/metrics/utils';
import { RamblerTabs } from 'common/components/RamblerComponents/RamblerTabs';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';

import { useTopicsMenu } from '../useTopicsMenu';

import s from './styles.module.css';

const TAIL_SPACE_MOBILE = 10;

type TopicsMenuRedesignPropsType = {
  projectId: number | undefined;
  activeTopicId: StateTopicType['id'] | undefined | null;
  className?: string;
};

/**
 * Меню рубрик редизайн версия
 * @param projectId - id вертикали;
 * @param activeTopicId - id топика к которому относится страница;
 * @param className - дополнительные стили.
 */
export const TopicsMenuRedesign = withErrorBoundary(
  function TopicsMenuRedesign({
    projectId,
    activeTopicId,
    className,
  }: TopicsMenuRedesignPropsType) {
    const isMobile = useAppSelector(selectIsMobile);
    const topics = useTopicsMenu(projectId);

    const { menuWrapperRef, reachGoalClickCallback } = useTopicsMenuReachGoal();

    const menuItems = useMemo(
      () =>
        topics.map(({ alias, name }) => ({
          url: `/${alias}/`,
          value: alias,
          label: name,
        })),
      [topics],
    );

    return (
      <nav ref={menuWrapperRef}>
        <RamblerTabs
          items={menuItems}
          value={activeTopicId ?? undefined}
          isToggles
          size="small"
          display="normal"
          ariaLabel="Меню рубрик"
          className={cn(s.tabs, className, !isMobile && s.desktop)}
          isScrollable={isMobile}
          tailSpace={isMobile ? TAIL_SPACE_MOBILE : 0}
          onChange={reachGoalClickCallback}
        />
      </nav>
    );
  },
);

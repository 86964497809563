import {
  selectClusterById,
  selectClusterPuids,
} from '../../commonData/entries/selectors';
import { selectPages } from '../selectors';

export const selectClusterPage = (state: IAppState) =>
  selectPages(state).cluster;

export const selectClusterPageClusterFeed = (state: IAppState): string[] =>
  selectClusterPage(state).clusterFeed;

export const selectClusterPageClusterIdByIndex =
  (index: number) =>
  (state: IAppState): string =>
    selectClusterPageClusterFeed(state)[index];

export const selectClusterPageTopNow = (state: IAppState) =>
  selectClusterPage(state).topNow;

export const selectClusterPageLoadingState = (state: IAppState) =>
  selectClusterPage(state).fetching;

export const selectClusterPageTopicId = (state: IAppState) =>
  selectClusterPage(state).topicId || null;

export const selectClusterPageCurrentClusterId = (state: IAppState) =>
  selectClusterPage(state).currentClusterId;

export const selectClusterPageCurrentClusterCardData = (state: IAppState) =>
  selectClusterById(selectClusterPageCurrentClusterId(state))(state);

export const selectClusterPageTextScale = (state: IAppState) =>
  selectClusterPage(state).textScaleValue;

export const selectClusterPageSpeaking = (state: IAppState) =>
  selectClusterPage(state).speaking;

export const selectClusterPagePuids = (state: IAppState) => {
  const clusterId = selectClusterPageClusterIdByIndex(0)(state);

  return selectClusterPuids(clusterId)(state) || {};
};

import cn from 'classnames';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { RamblerAd } from 'common/components/RamblerComponents/RamblerAd';
import {
  selectDisableAdv,
  selectReloadKey,
} from 'common/redux/appController/selectors';
import {
  selectBanners,
  selectIsDebug,
  selectIsMobile,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { getDeviceType } from 'config/constants/devices';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { metricsBatch } from 'server/collectors/prometheus/utils/metricsBatch';
import { COUNTERS_NAMES, ERROR_TYPE } from 'server/typings';

import {
  BANNERS_REQUIRED_FINANCE_DESKTOP,
  BANNERS_REQUIRED_FINANCE_MOBILE,
  BANNERS_WITH_MARGIN_BOTTOM,
  BANNER_PRELOAD_HEIGHT,
} from './config';
import { getBannerPuids } from './hooks/useBannerPuids';
import { useMisc } from './hooks/useMisc';
import { useViewType } from './hooks/useViewType';
import { IResult } from './typings';
import { getMarginPercent } from './utils';

import s from './styles.module.css';

type AdPropsType = {
  puids: PuidsType | undefined;
  name: Banners;
  idPrefix?: string;
  preloadHeight?: number;
  loadedStyle?: React.CSSProperties;
  loadedClassName?: string;
  style?: React.CSSProperties;
  className?: string;
  withoutMarginBottom?: boolean;
  disablePlaceholder?: boolean;
  bannerReplacementSlot?: React.ReactNode;
  isLazy?: boolean;
  isSticky?: boolean;
  onComplete?: (result: IResult) => void;
};

/**
 * Обертка над рекламным компонентом
 * @param puids - объект пуидов (настройкой рекламы).
 *  Компонент мержит их с пуидами в конфиге баннеров;
 * @param name - alias баннера (listing1 и др.);
 * @param idPrefix - Кастомный префикс для id;
 * @param preloadHeight - высота плейсхолдера при старте отрисовки баннера;
 * @param loadedStyle - Стили, которые применятся к контейнеру баннера после загрузки;
 * @param loadedClassName - дополнительный класс для плейсхолдера после загрузки;
 * @param style - стили компонента для рекламы;
 * @param className - дополнительные классы для рекламы;
 * @param withoutMarginBottom - флаг, что у рекламы будет не будет рисоваться нижний отступ по-умолчанию;
 * @param disablePlaceholder - дополнительный флаг, что плейсхолдер надо отключить;
 * @param bannerReplacementSlot - слот для вывода при отсутствии рекламного баннера;
 * @param isLazy - флаг отрисовки баннера по доскроллу;
 * @param isSticky - залипание при обратном скролле;
 * @param onComplete - Колбек, будет вызван после загрузки баннера (после резолва промиса Adf.banner.*).
 */
export const Ad = withErrorBoundary(function Ad({
  puids,
  name,
  preloadHeight,
  idPrefix,
  style,
  className = '',
  loadedStyle,
  loadedClassName,
  withoutMarginBottom = false,
  disablePlaceholder = false,
  bannerReplacementSlot = null,
  isLazy = true,
  isSticky,
  onComplete,
}: AdPropsType) {
  const isMobile = useSelector(selectIsMobile);
  const { banners } = useSelector(selectBanners, shallowEqual);
  const projectId = useSelector(selectProjectId);
  const reloadKey = useSelector(selectReloadKey);
  const disableAdv = useSelector(selectDisableAdv);
  const isDebug = useSelector(selectIsDebug);

  const bannerBegun = banners?.[name]?.begun;

  const loadedCallback = useCallback(
    (result: IResult = {}) => {
      const deviceType = getDeviceType(isMobile);

      // Batch рекламы
      metricsBatch.pushToCounters<COUNTERS_NAMES.Ad>({
        counterName: COUNTERS_NAMES.Ad,
        params: {
          deviceType,
          errorType: ERROR_TYPE.Ad,
          adName: name,
          adBegun: `${bannerBegun}`,
          successful: `${result.status}`,
          reason: typeof result.reason !== 'string' ? undefined : result.reason,
        },
      });
    },
    [isMobile, name, bannerBegun],
  );

  useViewType(name);

  const misc = useMisc();

  const isProjectFinance = projectId === PROJECT_IDS.Finance;

  const isBannerRequiredFinance = isMobile
    ? BANNERS_REQUIRED_FINANCE_MOBILE[name]
    : BANNERS_REQUIRED_FINANCE_DESKTOP[name];

  const handleComplete = useCallback(
    (result: IResult) => {
      loadedCallback(result);
      onComplete?.(result);
    },
    [loadedCallback, onComplete],
  );

  const placeholderHeight = preloadHeight ?? BANNER_PRELOAD_HEIGHT[name];

  // Ограничение на рендер, если страница ещё не отрендерилась
  if (!bannerBegun) return null;

  if ((!puids || !Object.values(puids)) && __DEV__) {
    console.error(`У баннера ${name} отсутствуют пуиды`);
  }

  return (
    <RamblerAd
      isDebug={isDebug}
      isLazy={isLazy}
      id={Number(bannerBegun)}
      reloadKey={reloadKey}
      style={style}
      preloadHeight={disablePlaceholder ? 0 : placeholderHeight}
      preloadClassName={s.placeholder}
      loadedStyle={loadedStyle}
      loadedClassName={loadedClassName}
      className={cn(className, s.root, s[name], {
        [s.hidden]: disableAdv,
        [s.marginBottom]:
          BANNERS_WITH_MARGIN_BOTTOM[name] && !withoutMarginBottom,
        [s.isSticky]: isSticky,
      })}
      options={getBannerPuids(
        isMobile,
        puids || {},
        /** Насыщение специфическими для баннера пуидами */
        banners?.[name]?.data || {},
        /** Хардкод пуидов для директорского кластера */
        banners?.[name]?.dir1 || {},
      )}
      idPrefix={idPrefix}
      misc={misc}
      onComplete={handleComplete}
      customRootMarginPercent={
        isProjectFinance && isBannerRequiredFinance
          ? getMarginPercent(name)
          : undefined
      }
      isDeferred={isProjectFinance && isBannerRequiredFinance}
    >
      {!disableAdv ? bannerReplacementSlot : null}
    </RamblerAd>
  );
}, true);

import { createSelector } from '@reduxjs/toolkit';

import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { IS_FORMAT_TOPIC, TOPIC_ALIAS_OTHER } from 'config/constants/topics';
import { isClusterData } from 'utils/createCardData';

import { selectAutotagById } from '../autotags/selectors';
import { selectItemsById } from '../items/selectors';
import { selectManualTagById } from '../manualTags/selectors';
import { selectResourceById } from '../resources/selectors';
import { selectCommonData } from '../selectors';
import { selectThemesByIds } from '../themes/selectors';

import { entriesAdapter } from './adapter';

export const selectEntries = (state: IAppState) =>
  selectCommonData(state).entries;

const selectors = entriesAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).entries,
);

export const selectCards = (state: IAppState) =>
  selectors.selectEntities(state);

export const selectAllEntries = (state: IAppState) =>
  selectors.selectAll(state);

export const selectCardById =
  (clusterId: CardData['id'] | null) =>
  (state: IAppState): ClusterData | CardData | undefined =>
    clusterId ? selectors.selectById(state, clusterId) : undefined;

export const selectCardsById =
  (clusterIds: CardData['id'][]) =>
  (state: IAppState): CardData[] =>
    clusterIds.map((clusterId) => selectors.selectById(state, clusterId));

export const selectManyCluster =
  (clusterIds: CardData['id'][]) => (state: IAppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

// Селектор кластера. Если данных недостаточно, то возвращается undefined.
export const selectClusterById =
  (clusterId: CardData['id'] | null | undefined) =>
  (state: IAppState): ClusterData | undefined => {
    const cluster = clusterId && selectors.selectById(state, clusterId);

    return cluster && isClusterData(cluster) ? cluster : undefined;
  };

export const selectClustersByIds =
  (clusterIds: CardData['id'][]) =>
  (state: IAppState): (ClusterData | undefined)[] =>
    clusterIds.map((id) => selectClusterById(id)(state));

export const selectClusterMainTopic =
  (clusterId: CardData['id'] | undefined) => (state: IAppState) =>
    selectTopicById(selectClusterById(clusterId)(state)?.mainTopicId)(state);

export const selectClusterTopics =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectClusterById(clusterId)(state)
      ?.topicIds.map((topicId) => selectTopicById(topicId)(state))
      ?.filter(Boolean) as StateTopicType[] | undefined;

export const selectClustersWithTopics = createSelector(
  [
    (state: IAppState) => state,
    (_state: IAppState, clusterIds: CardData['id'][]) => clusterIds,
  ],
  (state, clusterIds) => {
    const clusters = [] as (ClusterData & { topicData: StateTopicType })[];

    clusterIds.forEach((clusterId) => {
      const cluster = selectClusterById(clusterId)(state);
      const topicId = cluster?.mainTopicId || cluster?.topicIds?.[0];
      const topicData = topicId ? selectTopicById(topicId)(state) : null;

      if (cluster && topicData) {
        clusters.push({ ...cluster, topicData });
      }
    });

    return clusters;
  },
);

export const selectClusterAutotags =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.autotagIds.map((autotagId) =>
      selectAutotagById(autotagId)(state),
    );

export const selectClusterManualTags =
  (clusterId: CardData['id']) => (state: IAppState) =>
    (selectClusterById(clusterId)(state)
      ?.manualTagIds.map((manualTagId) =>
        selectManualTagById(manualTagId)(state),
      )
      .filter(Boolean) as ManualTagData[]) || [];

export const selectClusterExpertIds =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.expertIds;

export const selectClusterResource =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectResourceById(selectClusterById(clusterId)(state)?.resourceId)(state);

export const selectClusterPuids =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.puids;

export const selectEntriesFetchingClusters = (state: IAppState) =>
  selectEntries(state).fetchingClusters;

export const selectClusterRelated =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.related;

export const selectClusterType =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.type;

export const selectClusterThemeIds =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.themeIds || [];

export const selectClusterThemes =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectThemesByIds(selectClusterThemeIds(clusterId)(state))(state) || [];

export const selectClusterIsNewDraftFlag =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.isNewDraft;

export const selectClusterSortedTopics =
  (clusterId: CardData['id']) => (state: IAppState) => {
    const topics = selectClusterTopics(clusterId)(state) || [];
    let topicsData = topics || ([] as StateTopicType[]);

    if (topicsData.length <= 1) {
      return topicsData;
    }

    const cluster = selectClusterById(clusterId)(state);
    // Главная рубрика будет первой в списке
    const mainTopic = selectClusterMainTopic(clusterId)(state);

    // Не выводим рубрику "Другое" при наличии других рубрик
    // Удаляем главную рубрику чтобы отсортировать остальные
    topicsData = topicsData.filter(
      (topic) =>
        topic?.alias !== TOPIC_ALIAS_OTHER &&
        topic?.alias !== cluster?.topicAlias,
    );

    // Сначала тематические, потом форматные
    topicsData.sort((a: StateTopicType, b: StateTopicType) => {
      const isAFormat = IS_FORMAT_TOPIC[a.alias];
      const isBFormat = IS_FORMAT_TOPIC[b.alias];

      if (isAFormat === isBFormat) {
        return 0;
      }

      if (isAFormat) {
        return 1;
      }

      return -1;
    });

    // Возвращаем главную рубрику в начало списка (кроме рубрики Другое)
    if (cluster?.topicAlias !== TOPIC_ALIAS_OTHER && mainTopic)
      topicsData.unshift(mainTopic);

    return topicsData;
  };

export const selectClusterMoreItems =
  (clusterId: CardData['id']) => (state: IAppState) => {
    const itemIds = selectClusterById(clusterId)(state)?.itemIds;

    return itemIds?.map((id) => {
      const itemData = selectItemsById(id)(state);
      const resource = selectResourceById(itemData?.resourceId)(state);

      return { ...itemData, resource } as ClusterItemData & {
        resource: ClusterResourceType;
      };
    });
  };

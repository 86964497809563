import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const weekend: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 432189108,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 542288077,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 432189246,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.MarketingContent]: {
        display: 'sspScroll',
        begun: 527635339,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 446850000,
      },
      [Banners['240x400']]: {
        display: 'ssp',
        begun: 432189232,
      },
      [Banners['240x200']]: {
        display: 'ssp',
        begun: 432293986,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 432293988,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '186',
        },
        begun: 434019068,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '187',
        },
        begun: 434019076,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 432189118,
      },
      [Banners.Nativefooter]: {
        // native footer
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '357',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '847',
        },
        dir1: {
          [PUIDS.puid61]: '825',
        },
        begun: 434035352,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 432294010,
      },
      [Banners.Recommendation]: {
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '187',
        },
        begun: 455837798,
      },
      [Banners['Context270х120']]: {
        display: 'sspScroll',
        begun: 449040892,
      },
      [Banners.Sponsored]: {
        display: 'ssp',
        begun: 446850058,
      },
      [Banners.Adcenter]: {
        display: 'sspScroll',
        begun: 446849950,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 432293996,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 486071321,
        dir1: {
          [PUIDS.puid61]: '826',
        },
      },
      [Banners.BanWidget]: {
        display: 'ssp',
        begun: 476547200,
      },
      [Banners.BanMenu]: {
        display: 'ssp',
        begun: 476104604,
      },
      [Banners.MenuTeaser]: {
        display: 'ssp',
        begun: 477432115,
      },
      [Banners.Curtain]: {
        display: 'ssp',
        begun: 498566155,
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 530720627,
      },
      [Banners.NativeInRecs]: {
        display: 'sspScroll',
        begun: 579349067,
      },
      [Banners.Paid]: {
        display: 'sspScroll',
        begun: 579387078,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'WEEKENDRAMBLERRU_MAIN',
          puid18: 'WEEKENDRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498246,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579503446,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 461249682,
    banners: {
      [Banners.TopBanner]: {
        begun: 461251784,
        ampBegun: 491776183,
        p1: 'bzmet',
      },
      [Banners.Rich]: {
        begun: 461251788,
        ampBegun: 579334553,
      },
      [Banners.Fullscreen]: {
        begun: 461251782,
      },
      [Banners.Listing1]: {
        begun: 461251896,
      },
      [Banners.Listing2]: {
        begun: 461251902,
      },
      [Banners.Listing3]: {
        begun: 461251912,
      },
      [Banners.Listing4]: {
        begun: 461251914,
      },
      [Banners.Content1]: {
        begun: 461251794,
        ampBegun: 483278815,
        p1: 'bzaen',
      },
      [Banners.Content2]: {
        begun: 461251796,
        ampBegun: 483279765,
        p1: 'bzaeo',
      },
      [Banners.Content3]: {
        begun: 461251838,
        ampBegun: 483280457,
        p1: 'bzaep',
      },
      [Banners.Content4]: {
        begun: 461251872,
        ampBegun: 483281409,
        p1: 'bzaeq',
      },
      [Banners.Spec]: {
        begun: 461251934,
      },
      [Banners.ContentSpec]: {
        begun: 461251936,
      },
      [Banners.Inpage]: {
        begun: 461251786,
      },
      [Banners.Sponsored]: {
        begun: 461251932,
      },
      [Banners.Footer]: {
        begun: 461251892,
        ampBegun: 483282207,
        p1: 'bzaer',
      },
      [Banners.MarketingContent]: {
        begun: 527639589,
      },
      [Banners.MobileNative]: {
        data: {
          [PUIDS.puid61]: '434',
        },
        begun: 482145037,
      },
      [Banners.Paid]: {
        begun: 579387080,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'WEEKENDRAMBLERRU_MAIN',
          puid18: 'WEEKENDRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498248,
      },
    },
  },
};
